import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Persona from './persona';
import './comite.css';

const Comite = () => {
    const comite = [
        {
          name: 'FERNANDO CASTAÑEDA SABIDO',
          position: 'PRESIDENTE DEL COMITÉ',
          photo: 'static/comite_academico/1FernandoCastaneda.jpg',
          description: 'Doctor en Ciencias Sociales con especialidad en Sociología y maestro en Ciencias Sociales por el COLMEX, con estudios de doctorado en Pensamiento Político y Social en la Universidad de Sussex y Licenciado en Sociología por la Facultad de Ciencias Políticas y Sociales de la UNAM, de la que también ha sido director de 2008 a 2012 y de 2012 a 2016. Fue coordinador del Centro de Estudios de Opinión Pública (2003-2008) dentro de la misma facultad y fundó en 2005 la Revista Mexicana de Opinión Pública. Es investigador y profesor de carrera titular C de tiempo completo definitivo en el área de Sociología. Ha impartido diferentes cátedras a nivel licenciatura y posgrado y es autor de múltiples artículos en revistas especializadas y de capítulos en libros. Sus líneas de investigación son: teoría sociológica, filosofía política, opinión pública, inclusión-exclusión y sociología política.',
        },
        {
          name: 'CAROLA GARCÍA CALDERÓN',
          position: '',
          photo: 'static/comite_academico/2CarolaGarcia.jpg',
          description: 'Maestra y Licenciada en Ciencias de la Comunicación y doctora en Sociología por la Facultad de Ciencias Políticas y Sociales de la Universidad Nacional Autónoma de México. Realizó una especialización académica en Televisión Educativa y Cultural en el Instituto Nacional del Audiovisual en París, Francia. En 2016 se hizo acreedora al premio Universidad Nacional en Docencia en Ciencias Sociales que otorga la UNAM. Es miembro del Sistema Nacional de Investigadores (SNI) nivel II. Actualmente es Directora de la Facultad de Ciencias Políticas y Sociales.',
        },
        {
          name: 'GEORGINA CÁRDENAS ACOSTA',
          position: '',
          photo: 'static/comite_academico/3GeorginaCardenas.jpg',
          description: 'Doctora en Antropología Social por la ENAH, maestra en Estudios de Género por el COLMEX y Licenciada en Sociología por la UAM. Realizó un posdoctorado en el Centro de Investigaciones y Estudios de Género de la UNAM. Experta en temas de participación política de las mujeres y violencia política contra las mujeres. Integrante del SNI Nivel 1, de la red de politólogas “No Sin Mujeres” y coordinadora de la línea de género de la Cátedra UNESCO. En 2018 recibió la Medalla Omecíhuatl en la categoría 7, del Instituto de las Mujeres de la Ciudad de México por su contribución, impulso, promoción y garantía de los derechos políticos de las mujeres y en 2022 recibió el reconocimiento con la presea por parte del Consejo Nacional de Mujeres Empresarias, y el premio Napolitan Victory Awards 2022 en la categoría Global Democracy Award: Individuos.',
        },
        {
          name: 'RAÚL MARTÍNEZ SOLARES PIÑA',
          position: '',
          photo: 'static/comite_academico/4RaulMartinez.jpg',
          description: 'Maestro en Administración de Negocios por el ITAM y Licenciado en Ciencias Políticas y Administración Pública por la Universidad Iberoamericana. Politólogo, mercadólogo, financiero y especialista en economía conductual, estrategia de negocios, inversión de capital de riesgo, ahorro e inversión inmobiliaria. Participante en seminarios y cursos en temas de mercadotecnia, negocios e inversiones en diversas universidades, incluyendo una Certificación en Fusiones y Adquisiciones por la Escuela de Negocios Booth, de la Universidad de Chicago. Actualmente es CEO de Fibra Educa. Además, es Presidente del Consejo para el Fomento del Ahorro Educativo de Mexicana de Becas, miembro de American Economic Association y de Society for the Advancement of Behavioral Economics, profesor en la Facultad de Economía de la UNAM desde hace más de 10 años, columnista en El Economista y conferencista en temas demográficos, conductuales, económico financieros, de ahorro para el retiro y ahorro previsional de largo plazo. Ha ocupado cargos en el sector privado (CEO y  VP de M&A Grupo Nacer Global y CCO de Afore XXI) y en el sector público (SHCP, SEGOB). Con la UNAM ha participado en proyectos como Cultura Financiera de Jóvenes BANAMEX-UNAM y Voto Informado con la FCPyS y Curso de Finanzas Personales Para Jóvenes con la FE y PENSIONISSSTE.',
        },
        {
          name: 'ROSA MARÍA MIRÓN LINCE',
          position: '',
          photo: 'static/comite_academico/5RosaMiron.jpg',
          description: 'Doctora y maestra en Ciencia Política por la Facultad de Ciencias Políticas y Sociales de la UNAM y Licenciada en Sociología. Diplomada en Derecho Electoral y en Metodología de la Investigación Social, ambos por el Instituto de Investigaciones Jurídicas de la UNAM. Nivel D en el Programa de Primas al Desempeño del Personal Académico de Tiempo Completo (PRIDE). Nivel 3 en el Sistema Nacional de Investigadores. Coordinadora del Programa de Posgrado en Ciencias Políticas y Sociales de la UNAM (2016-2019). Consejera Electoral del Consejo General del Instituto Electoral del Distrito Federal (1999-2006). Miembro del Comité Técnico de Apoyo al PREP del INE durante el proceso electoral federal 2017-2018. Miembro del Comité Técnico de Evaluación del Fondo de Apoyo para la Observación Electoral, INE, durante el proceso electoral 2020-2021.',
        },
        {
          name: 'JULIO CÉSAR PONCE QUITZAMAN',
          position: '',
          photo: 'static/comite_academico/6JulioPonce.jpg',
          description: 'Doctor, maestro y licenciado en Derecho por la UNAM y diplomado por la Universidad de California Berkeley y por la Universidad La Salle México. Integrante fundador de la Asociación Iberoamericana de Derecho Electoral y del Grupo de Investigación, Estado, Gobierno, Cultura Política y Participación Ciudadana del Consejo Nacional de Ciencia y Tecnología. Colaborador de World Justice Project y director Jurídico de la Revista de Derecho Estasiológico de la UNAM, así como integrante de los comités editoriales y editorialista de la Revista Académica de la Facultad de Derecho de la Universidad La Salle y de la revista Matices del Posgrado de la UNAM. Investigador de la Cátedra UNESCO Universidad e Integración Regional. Actualmente Jefe de la División de Ciencias Sociales de la Facultad de Estudios Superiores Aragón de la UNAM.',
        },
        {
          name: 'HADA MELISSA SÁENZ VELA',
          position: '',
          photo: 'static/comite_academico/7HadaSaenz.jpg',
          description: 'Doctora y Maestra en Economía Regional y Licenciada en Matemáticas Aplicadas. Actualmente cuenta con Perfil PRODEP y es miembro del SIN, y forma parte de las actividades del Cuerpo Académico de Economía regional aplicada. Profesora-investigadora del CISE-UAdeC. Sus líneas de investigación versan sobre metodologías aplicadas al análisis de las brechas socioeconómicas, medición multidimensional de la pobreza, economía de la salud y representación y participación política de la mujer.  Ha trabajado temas de representación política de la mujer en Coahuila, esto al ser coautora del capítulo correspondiente en el libro “La paridad, una realidad aún por construir en los congresos locales de México”, el cual fue editado por el INE. Asimismo, participó del libro “Avances y desafíos en la construcción de la agenda de género en los Congresos locales en México, 2018-2022”, que actualmente se encuentra en proceso de edición. En ese sentido, también colaboró en un proyecto entre el CRIM de la UNAM y el INE sobre la violencia política contra la mujer en razón de género durante el proceso electoral 2020-2021.',
        },
        {
          name: 'MARTHA SINGER SOCHET',
          position: '',
          photo: 'static/comite_academico/7MarthaSinger.jpg',
          description: 'Doctora en Ciencias Políticas y Sociales con orientación en Ciencia Política por la UNAM y con una especialización en Derecho Constitucional y Ciencia Política por el Centro de Estudios Constitucionales de Madrid, España; maestra en Ciencia Política y Licenciada en Sociología. Su línea de investigación se ha enfocado al estudio de la democracia, desarrollando aspectos referentes a la participación y representación política, en particular los concernientes a los derechos políticos indígenas, los partidos políticos y procesos electorales. Profesora titular de tiempo completo, definitiva, adscrita al Centro de Estudios Políticos de la Facultad de Ciencias Políticas y Sociales de la Universidad Nacional Autónoma de México. Miembro del Sistema Nacional de Investigadores, nivel I e integrante del Comité Académico de la Cátedra Francisco I. Madero UNAM-INE.',
        },
        {
          name: 'DANIEL TACHER CONTRERAS',
          position: '',
          photo: 'static/comite_academico/8DanielTacher.jpg',
          description: 'Doctor en Ciencia Política, maestro en Estudios Políticos y Sociales y Licenciado en Ciencia Política y Administración Pública por la Facultad de Ciencias Políticas y Sociales de la Universidad Nacional Autónoma de México. Especialista en temas de Voto de los Mexicanos Residentes en el Extranjero, Elecciones e Instituciones Electorales (Derecho Electoral) y Migración, así como Derechos Humanos y Derechos Políticos. Profesor-investigador de la licenciatura en Ciencia Política y Administración Urbana de la Universidad Autónoma de la Ciudad de México con licencia. Miembro del Sistema Nacional de Investigadores nivel 1 y miembro de Iniciativa Ciudadana para la Promoción de la Cultura del Diálogo, A.C. Cuenta con más de 20 obras publicadas entre libros de autoría propia, artículos en revistas especializadas y capítulos de libros.',
        },
      ];
  return (
    <React.Fragment>
        <Container>
            <div className='head-center py-5'>
                <h1>COMITÉ ACADÉMICO</h1>
            </div>
            <Row>
                <Col sm={6} xs={12}>
                    <p className='text-justification'>
                    Contamos con un Comité Académico, integrado por personas especialistas dedicadas a la docencia,
                    investigación y difusión de temas relacionados con la educación cívica, participación ciudadana
                    y el sistema político en México, éste funge como eje rector para el riguroso análisis y posterior
                    elaboración del cuestionario. 
                    </p>
                </Col>
                <Col sm={6} xs={12} className='img-center-align'>
                    <img src='static/logos/UNAM_escudo.png' className='img-comite' />
                </Col>
            </Row>
        </Container>
        <Container>
            <div className='head-center pt-3'>
                <h3>INTEGRANTES</h3>
            </div>
            {comite.map((member, index) => {
                return (
                      <Persona
                        name={member.name}
                        position={member.position}
                        photo={member.photo}
                        description={member.description}
                        index={index}
                      />
                  );
              })}
        </Container>
        <br/> <br/>
    </React.Fragment>
  )
}

export default Comite
