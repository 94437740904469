import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import Carrusel from './carrusel'
import InvitaCandidato from './invitaCandidato/index'
import BottomSection from './bottom_section'
import RedesSection from './redes_section'
import CounterSection from './counter_section'
import ModalForo from './modal_foro'






const Inicio = ({setKey, handleSelect, showForo, closeForo, openForo}) => {

  




  return (
    <Container>
        <Carrusel setKey={setKey} openForo={openForo}/>
        <InvitaCandidato setKey={setKey} handleSelect={handleSelect}/>
        <br/> <br/>
        <CounterSection />
        <br/> <br/>
        <RedesSection />
        <br/> <br/>
        <BottomSection/>
        <ModalForo showForo={showForo} closeForo={closeForo}/>
    </Container>


  )
}

export default Inicio
