import React from "react";
import { Row, Col } from "react-bootstrap";

const Persona = ({name, position, photo, description, index }) => {

    return (
        <div>
        {(index % 2) === 0 ?
        <Row className="py-4">
            <Col xs={12} className='col-center py-3'>
                <Row>
                    <Col xs={12} className='col-center'>
                        <h5>{name}</h5>
                    </Col>
                    <Col xs={12} className='col-center'>
                        {position}
                    </Col>        
                </Row>
            </Col>
            <Col sm={5} xs={12} >
                <Row>
                    <Col xs={12} className='col-center'>
                        <img src={photo} className='img-perfil-comite' />
                    </Col>        
                </Row>
            </Col>
            <Col sm={7} xs={12} >
                <p className="text-justification">{description}</p>
            </Col>
        </Row>
        :
        <Row>
            <Col xs={12} className='col-center py-3 '>
                <Row>
                    <Col xs={12} className='col-center'>
                        <h5>{name}</h5>
                    </Col>
                    <Col xs={12} className='col-center'>
                        {position}
                    </Col>        
                </Row>
            </Col>
                <Col sm={7} xs={12} >
                    <p className="text-justification">{description}</p>
                </Col>
                <Col sm={5} xs={12} >
                    <Row>
                        <Col xs={12} className='col-center'>
                            <img src={photo} className='img-perfil-comite' />
                        </Col>
                        <Col xs={12} className='col-right'>
                            {position}
                        </Col>
                    </Row>
                </Col>
        </Row>
        }
        </div>
    );
};

export default Persona;