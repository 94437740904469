import React, { useState } from 'react'
import { Container, Col, Row, Button, Modal} from 'react-bootstrap'
// import { getApiUrl } from "../../../actions/helper";
import {saveAs} from "file-saver";
import './diviertete.css';


const DivierteteAprende = () => {
  const [showCrucigrama, setShowCrucigrama] = useState(false);
  const [showSopa, setShowSopa] = useState(false);

  const handleCloseCrucigrama = () =>{
    setShowCrucigrama(false);
  }
  const handleCloseSopa = () =>{
    setShowSopa(false);
  }
  const handleDownload = (url, nameFile)=>{
    saveAs(url, nameFile);
   }

  return (
    <React.Fragment>
    <Container>
      <Row>
        <Col xs={12} className='pb-3 pt-3'>
            <h1>DIVIÉRTETE Y APRENDE</h1>
            <div className='text-center'>
              <p>
                Pasa el tiempo aprendiendo con nuestras dinámicas y prepárate para las elecciones
              </p>
            </div>
            <div className='text-center'>
              <h3>Crucigrama</h3>
            </div>
        </Col>
        <Col sm={6} xs={12}>
            <img src='static/dinamicas/DinamicaCrucigrama_A.jpg' className='img-ve'
              onClick={() => handleDownload(`/static/dinamicas/DinamicaCrucigrama_A.jpg`, 'Crucigrama.jpg')}>
            </img>
        </Col>
        <Col sm={6} xs={12}>
          <img src='static/dinamicas/DinamicaCrucigrama_HV.jpg' className='img-ve'></img>
        </Col>
        <Col xs={12} className='py-4'>
          <Container className='img-right'>
            {/* <a href='static/dinamicas/DinamicaCrucigrama_R.jpg' target={'_blank'}> */}
              <Button variant="primary" size="lg" active onClick={() => setShowCrucigrama(true)}>
                Resultado
              </Button>
            {/* </a> */}
          </Container>
        </Col>
      </Row>
    </Container>
    <div className='full-width'>
    <Row className='row-mision-vision'>
      <Col xs={12} className='pb-2'>
        <div className='text-center'>
          <h3>Sopa de letras</h3>
        </div>
      </Col>
      <Col sm={3} xs={0}></Col>
      <Col sm={6} xs={12}>
          <img src='static/dinamicas/DinamicaSopa_A.jpg' className='img-ve'
            onClick={() => handleDownload(`/static/dinamicas/DinamicaSopa_A.jpg`, 'SopaDeLetras.jpg')}>
          </img>
      </Col>
      <Col sm={3} xs={0}></Col>
      <Col xs={12} className='py-4'>
        <Container className='img-right'>
          {/* <a href='static/dinamicas/DinamicaSopa_B.jpg' target={'_blank'}> */}
            <Button variant="primary" size="lg" active onClick={() => setShowSopa(true)}>
              Resultado
            </Button>
          {/* </a> */}
        </Container>
      </Col>
    </Row>
  </div>
  <Container className='pt-4 pb-5'>
    <Row>
      <Col xs={12} className='pb-3 pt-3'>
          <div className='text-center'>
            <h5>¿Quieres destacar en las conversaciones sobre las elecciones con tus amigos(as)?</h5>
          </div>
          <div className='text-center'>
            <h3>Descarga nuestros stickers y compártelos :)</h3>
          </div>
      </Col>
      <Col sm={6} xs={12} className='text-center-align'>
          <p>
            Solo tienes que seguir estas sencillas instrucciones:<br/><br/>
            1. Descarga la app <a href='https://sticker.ly/'>Sticker.ly</a> (disponible para IOS y Android)<br/>
            2. Búscalos en este enlace <a href='http://sticker.ly/s/VN61TJ' target={'_blank'}>http://sticker.ly/s/VN61TJ</a><br/>
            3. Añadir a WhatsApp  / IMessage*<br/>
          </p>
      </Col>
      <Col sm={6} xs={12} className='img-center'>
        <a href='http://sticker.ly/s/VN61TJ' target={'_blank'}>
          <img src='static/dinamicas/stickers.png' className='img-ve'></img>
        </a>
      </Col>
    </Row>
  </Container>
  <Modal show={showCrucigrama} onHide={handleCloseCrucigrama} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
    <Modal.Body>
      <div className='img-center'>
        <img className='img-modal' src='static/dinamicas/DinamicaCrucigrama_R.jpg'
          onClick={() => handleDownload(`/static/dinamicas/DinamicaCrucigrama_R.jpg`, 'RespuestaCrucigrama.jpg')}>
        </img>  
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseCrucigrama}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  <Modal show={showSopa} onHide={handleCloseSopa} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
    <Modal.Body>
      <div className='img-center'>
        <img className='img-modal' src='static/dinamicas/Dinamicasopa_B.jpg'
          onClick={() => handleDownload(`/static/dinamicas/Dinamicasopa_B.jpg`, 'RespuestaSopaDeLetras.jpg')}>
        </img>  
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseSopa}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  </React.Fragment>
)}
export default DivierteteAprende
