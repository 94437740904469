
import axios from "axios";
//import { useDispatch } from 'react-redux'
import { getApiUrl } from "./helper";

import { setCandidateAnswerCandidateID,setCandidateAnswerCandidateEstado,  setCandidateAnswers, setCandidateAswer , setCandidateAswerMultiple,    saveCuestionariosCandidato,updateAnswerVisibility ,setCurrentAswer,setUserAswer,setUserAswers,setUserInfo,setCurrentQuestionToShow,setUserAswersCode,setUserAswerMultiple,updateAnswerVisibilityMultiple} from '../reducers/appReducer'




export const saveUserCuestionarioAction = (dataJSON) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
 

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${window.location.protocol}//${getApiUrl()}/cuestionarios/save`,
      dataJSON,
      config
    );
   
    
    dispatch(setUserAswers(JSON.parse(data.answers)   ))
    dispatch(setUserAswersCode(data.code))

return data.code
    //localStorage.setItem("personaInfo", JSON.stringify(data));
  } catch (error) {
    console.log("Error al guardar saveCuestionarioAction")
    console.log(error.response.data)
  }
};


export const setUserAswersAction = (answers) => async (dispatch) => {
  dispatch(setUserAswers(answers))
};



export const setUserAswersCodeAction = (code) => async (dispatch) => {
  dispatch(setUserAswersCode(code))
};



export const updateAnswerVisibilityAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(updateAnswerVisibility(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};

export const updateAnswerVisibilityMultipleAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(updateAnswerVisibilityMultiple(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};


export const setCurrentAswerAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setCurrentAswer(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};


export const setUserAswerAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    
    dispatch(setUserAswer(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};


export const setUserAswerMultipleAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
   
    dispatch(setUserAswerMultiple(data))



    return data
  } catch (error) {
    console.log("Error al guardar setUserAswerMultipleAction")
    console.log(error)
  }
};






export const setUserInfoAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setUserInfo(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};


export const setCurrentQuestionToShowAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setCurrentQuestionToShow(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};










export const saveAdminCuestionarioAction = (dataJSON) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
   
    

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${window.location.protocol}//${getApiUrl()}/admin/cuestionarios/save`,
      dataJSON,
      config
    );

    dispatch(saveCuestionariosCandidato(data))

    //localStorage.setItem("personaInfo", JSON.stringify(data));
  } catch (error) {
    console.log("Error al guardar saveCuestionarioAction")
    console.log(error.response.data)
    
  }
};


export const fetchCuestionarioAction = (userCode) => async (dispatch) => {
  //const dispatch = useDispatch()
  
  try {


    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${window.location.protocol}//${getApiUrl()}/cuestionarios/fetchUserCuestionario`,
      {params: {"userCode":userCode}},
      config
    );

 
    
    if(data.status==200){
      dispatch(setUserAswers(JSON.parse(data.answers)))
      dispatch(setUserAswersCode(userCode))

    }
    dispatch(setUserInfo(  {email:"",age:0,genero:-1,estado:data.estado} ))
 
    

    return data
  } catch (error) {
    console.log("Error al guardar saveCuestionarioAction")
    console.log(error)
    Promise.reject(error)
  }

  //dispatch(dispatchdummy("respuestas bobas"))
};


export const fetchCuestionariosCandidatos = () => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${window.location.protocol}//${getApiUrl()}/cuestionarios/candidates`,
      {params: {}},
      config
    );
   
    


    dispatch(saveCuestionariosCandidato(data))

    //localStorage.setItem("personaInfo", JSON.stringify(data));
  } catch (error) {
    console.log("Error al guardar saveCuestionarioAction")
    console.log(error.response.data)
  }
};




export const setCandidateAnswerCandidateIDAction = (candidateID) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setCandidateAnswerCandidateID(candidateID))

  } catch (error) {
    console.log(error)
  }
};

export const setCandidateAnswerCandidateEstadoAction = (candidateID) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setCandidateAnswerCandidateEstado(candidateID))

  } catch (error) {
    console.log(error)
  }
};

export const setCandidateAnswerAction = (answers) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    dispatch(setCandidateAnswers(answers))

  } catch (error) {
    console.log(error)
  }
};


export const setCandidateAswerAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    
    dispatch(setCandidateAswer(data))

  } catch (error) {
    console.log("Error al guardar updateAnswerVisibilityAction")
    console.log(error)
  }
};


export const setCandidateAswerMultipleAction = (data) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
   
    dispatch(setCandidateAswerMultiple(data))



    return data
  } catch (error) {
    console.log("Error al guardar setUserAswerMultipleAction")
    console.log(error)
  }
};

export const saveCandidateCuestionarioAction = (dataJSON) => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
 

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${window.location.protocol}//${getApiUrl()}/admin/cuestionarios/save`,
      dataJSON,
      config
    );
   
    
    dispatch(setUserAswers(JSON.parse(data.answers)   ))
  

return data.code
    //localStorage.setItem("personaInfo", JSON.stringify(data));
  } catch (error) {
    console.log("Error al guardar saveCuestionarioAction")
    console.log(error.response.data)
  }
};



