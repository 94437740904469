import React from 'react'
import { Container } from 'react-bootstrap'


const Cuestionarios = () => {
  return (
    <Container>
        <div>CUestionarios mijoasdas</div>
    </Container>


  )
}

export default Cuestionarios
