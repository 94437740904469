import React from 'react'
import { Modal, Button, Container } from 'react-bootstrap'

const TarjetaModal = (props) => {
  return (
    <Modal show={props.show} 
          onHide={props.handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          fullscreen={true}
    >
        <Modal.Body>
            <Container>
                <img src='static/banner_header/BannerNosVemosPronto.png' className='img-ve'></img>
            </Container>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
            Cerrar
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default TarjetaModal