import React from 'react'
import  { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Badge,Alert,Form,Row, Col,Button,Card,ListGroup ,Accordion} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {setCandidateAnswerCandidateIDAction, setCandidateAnswerCandidateEstadoAction,setCandidateAnswerAction,setCandidateAswerAction,setCandidateAswerMultipleAction, saveCandidateCuestionarioAction,   saveUserCuestionarioAction,updateAnswerVisibilityAction,setCurrentAswerAction,setUserAswerAction,setUserInfoAction,setCurrentQuestionToShowAction,fetchCuestionarioAction,setUserAswersCodeAction,setUserAswersAction,setUserAswerMultipleAction,updateAnswerVisibilityMultipleAction} from '../../../../actions/cuestionariosActions'


import cuestionarioJSONedoMex from '../../../../cuestionarios_json/cuestionario_de_usuario_edo_mex.json';
import cuestionarioJSONcoahuila from '../../../../cuestionarios_json/cuestionario_de_usuario_coahuila.json';


function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}



const Cuestionario = (props) => {

	const dispatch = useDispatch()
	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
	const [cuestionario, setCuestionario] = useState( [] );

  useEffect(() => {

   
  	if(candidateAnswer.estado !== -1){
  		if(candidateAnswer.estado==0){
        setCuestionario(cuestionarioJSONedoMex)
		  }else{
	      setCuestionario(cuestionarioJSONcoahuila)
		  }	
  	}
   	
    
    
  }, [candidateAnswer])











  return (
    

    //<pre>{JSON.stringify(cuestionario , null, 2)}</pre>
    <Row>
      <Col className='col-12'>

      	
       	{cuestionario.map((question,index)=>{
          return(
          	<Block key={question.id} question={question} />
          )	
       		

        })}
      </Col>
    </Row>
  )

}


function Block(props) {

  const currentQuestionToShow  = props.question
 switch (currentQuestionToShow.name) {
 	  case "question":
 	    return(

 	      <Question question={currentQuestionToShow}/>
        
 	    )
 	}
 	         
 
    
}



function Question(props) {

  const currentQuestionToShow  = props.question

    return (
      <Row className='subquestions shadow form-contro mt-3 mb-3 pt-2 pb-2'>

          <Col xs={12}>
            {/*<Alert key='success' variant='primary'>*/}
              <Row>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12}> 
                  <h5>{currentQuestionToShow.attributes.block}</h5>
                  <h4>{currentQuestionToShow.attributes.label}</h4>
                </Col>
              </Row>                     
            {/*</Alert>*/}
            
              
          </Col>
          <Col xs={12}>
            <Row> 
              <Col xs={12} sm={12} md={12} lg={12}>
                <Input key={uuidv4()} question={currentQuestionToShow}/>
              </Col>
              
            </Row>
            
              
           
          </Col>
 
          
      </Row>
    );
}


function Input(props) {
const currentQuestionToShow  = props.question

  switch (currentQuestionToShow.attributes.type) {
    
    case "multiple":
      return(
        <InputMultiple question={currentQuestionToShow}/>
        
      )
    case "dropdown":
      return(
        <InputDropdown  question={currentQuestionToShow}/>
      )
    case "priority":
      return(
        <InputPriority question={currentQuestionToShow} />
      )

    case "multiple-text":
      return(
        <InputMultipleText question={currentQuestionToShow} />
      )




      
  }
}



function InputDropdown(props) {

  const dispatch = useDispatch()


  const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
  const currentQuestionToShow  = props.question


  //const [cuestionario, setCuestionario] = useState(cuestionarioJSON);


  

  useEffect(() => {
    /*console.log("IN useEffect InputDropdown")  

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    if (answerFound.value != -1 || answersCode!= undefined){
      dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true}))  
    }*/
    
  }, [candidateAnswer]) 



  const onInputChangeDropdown = (event) => {  
   	dispatch(setCandidateAswerAction({id:currentQuestionToShow.id,   value : event.target.value}))


  }


  const findCurrentAnswer = () => {  

    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );
    if (answerFound)
    	return answerFound.value
    else
    	return -1
  }


  function ShowDropDown(){
    return(
        <Form.Select  defaultValue={findCurrentAnswer()} size="lg" id={currentQuestionToShow.id} onChange={onInputChangeDropdown}>
          <option  disabled value='-1'>Selecciona una opción</option>
          {currentQuestionToShow.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
      )
  }

  



  //<pre>{JSON.stringify(currentQuestionToShow , null, 2)}</pre>
      return(

      		<Row className=''>
      			<Col>
		         	<ShowDropDown />
		         </Col>
		      </Row>


          
      )
  
    
}




function InputMultiple(props) {

  const dispatch = useDispatch()

  const currentQuestionToShow  = props.question
 const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)


  

  function ShowDropDownForSubQuestion(props){

  
  const [subquestion, setsubquestion] = useState(props.subquestion);
  


  

  const findCurrentAnswer = (subQuestionId) => {  
    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );


    

    if(answerFound){
    	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
    	if(subAnswerFound)
    		return subAnswerFound.value
    	else
    		return -1	
    }else
    	return -1
  }

  const onInputChangeDropdown = (subQuestion) =>(event) => {  
    dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id }))
  }


    return(
        <Form.Select  defaultValue={findCurrentAnswer(subquestion.id)} size="lg" id={subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
          <option  key={uuidv4()} disabled value='-1'>Selecciona una opción</option>
          {subquestion.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
    )
  }


  

  return(

      <Row>
        {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
          <Col xs={12} key={uuidv4()} className="mt-2">
              <Row className='mb-4'>
                <Col xl={12}>
                   {subquestion.attributes.label}
                </Col> 
                <Col xl={12}>
                  <ShowDropDownForSubQuestion subquestion={subquestion}/> 
                </Col>
              </Row>
          </Col>
        ))} 

      </Row>
  ) 
}





function InputPriority(props) {

  const dispatch = useDispatch()

  const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
  const currentQuestionToShow  = props.question
 const [choices, setchoices]= useState(currentQuestionToShow.attributes.choices);
  


  const restartSelects = (optionSelected) => {  
  
    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

    answerFound.subquestions.forEach(choice => {
    	//dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id, value:-1, subQuestionId:choice.id }))

    	dispatch(setCandidateAswerMultipleAction({id:answerFound.id, value:-1, subQuestionId:choice.id }))
    });


        
  }


  

  function ShowDropDownForPrioritySelect(props){

    const [subquestion, setsubquestion] = useState(props.subquestion);
    const [choices, setchoices]= useState([]);



    useEffect(() => {
      setAvailableOptions()
     	
    
    }, [])  

  

    const findCurrentAnswer = (subQuestionId) => {  
     
      const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

      if(answerFound){
	      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
	      return subAnswerFound  
	    }else
	    	return -1
    }


    const onInputChangeDropdown = (subQuestion) => (event) => {  
  

    dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id, value:event.target.value, subQuestionId:subQuestion.id }))

    /*.then((value) => {
        
     

    }).catch((error) =>{
      
      console.log(error)
    })*/
    }

    const getOptionSelectedLabel = (subQuestionId) => {  
      const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

      if(answerFound){
      	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
        const choice = currentQuestionToShow.attributes.choices.find(choice => choice.value === subAnswerFound.value)
        return choice.label
      }else{
      	return ""
      }

    }


    const setAvailableOptions = () => {  
      var choices = []
      var answerFound = null
      var subAnswerFound =null

      currentQuestionToShow.attributes.choices.forEach(choice => {

       	answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

       	if(answerFound){
       		subAnswerFound =answerFound.subquestions.find(answer => answer.value === choice.value )
        

	        if(subAnswerFound == undefined){
	          choices.push({label: choice.label,value:choice.value})  
	        }	
       	}
        



      })

      setchoices(choices)

    }

   
    return(
      <div>
        
        {  findCurrentAnswer(subquestion.id).value ===-1 ? (
            <Form.Select  defaultValue={findCurrentAnswer(subquestion.id).value} size="lg" id={"forms-"+subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
            <option  key={uuidv4()}  value='-1'>Selecciona una opción</option>
            {choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
              
            
            ))}
          </Form.Select>
          ) : (
            <div className="form-control"> {getOptionSelectedLabel(subquestion.id) } </div>
          )

        }
      </div>
    )
  }




  return(
    
            <Row >
              {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
                <Col xl={12}  key={uuidv4()} className="mt-2">
                  <Form.Group className="mb-2" controlId={"formg-"+subquestion.id}>
                    <Form.Label>{subquestion.label}</Form.Label>
                      <ShowDropDownForPrioritySelect subquestion={subquestion}/> 
                  </Form.Group>
                </Col>
              ))}
              <Col xl={12}  key={uuidv4()} className="mt-2">
                <Button variant="primary" type="button" onClick={restartSelects}>Reiniciar</Button>
              </Col>
            </Row>
  ) 
  
    
}




function ShowTextArea(props){
	const dispatch = useDispatch()
	const currentQuestionToShow  = props.question
  const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
  const [subquestion, setsubquestion] = useState(props.subquestion);
  const [text, setText] = useState("");
  var key = uuidv4()
  

  useEffect(() => {

	//dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id }))
  	if(text===""){
  		setText(findCurrentAnswer())
  	}
  }, []) 


  const findCurrentAnswer = () => {  
    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

    if(answerFound){
    	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subquestion.id );
    	if(subAnswerFound)
    		return subAnswerFound.value
    	else
    		return ""
    }else
    	return "" 

  
  }

  const handleInputBlur = event => {
    
  	dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : text, subQuestionId:subquestion.id }))
  	console.log("lost focus")
  }

  const onInputChange = event => {  
  
  	console.log(event.target.value)
  // dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subquestion.id }))
  	setText(event.target.value)
  }

 	//<Form.Control as="textarea" key={key} rows={3} value={text}  onChange={onInputChange() }/>
  //<input type='text' onChange={onInputChange} onBlur={handleInputBlur} value={text} />
    return(
        

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
	        <Form.Label>Example textarea</Form.Label>
	        
	        
	        <Form.Control as="textarea"  rows={3} onChange={onInputChange} onBlur={handleInputBlur} value={text}/>
	    </Form.Group>
    )
  } 



function InputMultipleText(props) {

  	const dispatch = useDispatch()

 	const currentQuestionToShow  = props.question
	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
	


/*	const findCurrentAnswer = (subQuestionId) => {  
	    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

	    if(answerFound){
	    	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
	    	if(subAnswerFound)
	    		return subAnswerFound.value
	    	else
	    		return ""
	    }else
	    	return ""
  }

  const onInputChange = (subQuestion) =>(event) => {  
  	//setText(event.target.value)
    //dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id }))
  } 

  

  useEffect(() => {

	//dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id }))

  }, [candidateAnswer]) */
  

  return(

      <Row>
        {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
          <Col key={subquestion.id+"cont"}  xs={12} key={uuidv4()} className="mt-2">
              <Row className='mb-4'>
                <Col xl={12}>
                   {subquestion.attributes.label}
                </Col> 
                <Col xl={12}>
                  <ShowTextArea key={subquestion.id} question={currentQuestionToShow} subquestion ={subquestion}/>
                </Col>
              </Row>
          </Col>
        ))} 

      </Row>
  ) 
}




const NewCuestionario = () => {
	const dispatch = useDispatch()

	const { candidateSlug } = useParams();
	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
	const candidatos   = useSelector(state => state.appData.candidatos)

	const [candidate, setcandidate]= useState(undefined);

	

	useEffect(() => {
      
      if(candidatos.length >0){
      	if(candidate==undefined ){
	      	const candidateFound = candidatos.find(candidato => candidato.slug === candidateSlug );
		    setcandidate(candidateFound)
		    dispatch(setCandidateAnswerCandidateIDAction(candidateFound.id))  
		    dispatch(setCandidateAnswerCandidateEstadoAction( candidateFound.estado) )



		    if(candidateFound.answers == undefined || candidateFound.answers.length == 0){

		    	var cuestionario =[]
		        if(candidateFound.estado==0){
		          cuestionario = cuestionarioJSONedoMex
		        }else{
		          cuestionario = cuestionarioJSONcoahuila
		        }
		    	
		    	const userAnswers=[]
			      cuestionario.forEach(question => {
			            if(question.name === "question"){

			              var answer = {id:question.id, value:-1, subquestions:[]}

			              if(question.attributes.type=="multiple"){
			                question.attributes.subquestions.forEach(subquestion => { 
			                  answer.subquestions.push({id:subquestion.id, value:-1})
			                });
			              }
			              if(question.attributes.type=="priority"){
			                question.attributes.subquestions.forEach(subquestion => { 
			                  answer.subquestions.push({id:subquestion.id, value:-1})
			                });
			              }

			              if(question.attributes.type=="multiple-text"){
			                question.attributes.subquestions.forEach(subquestion => { 
			                  answer.subquestions.push({id:subquestion.id, value:""})
			                });
			              }

			              userAnswers.push( answer )
			              
			            }
			      });
			      dispatch(setCandidateAnswerAction(userAnswers))

		    }else{
		    	dispatch(setCandidateAnswerAction(candidateFound.answers))	
		    }


		    //

		}

      }
    
    }, [candidatos,candidate])  



	const saveAnswers = () => {
		
		

    const answersClone = Object.assign({}, candidateAnswer); 

    answersClone.answers = JSON.stringify(answersClone.answers)

    

    dispatch(saveCandidateCuestionarioAction(  answersClone  )).then((value) => {
    
    /*dispatch(setCandidateAnswerCandidateIDAction("-1"))  
	  dispatch(setCandidateAnswerCandidateEstadoAction( "-1") )
	  dispatch(setCandidateAnswerAction([])) */

    Swal.fire({
        title: '<strong>Cuestionario guardado</strong>',
        icon: 'success', 
        html:'' ,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> OK!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>'
      })

	  

    }).catch((error) =>{
      
      console.log(error)
    })
 	
}




	return (

	    <Row className="justify-content-md-center">
  			<Col className="col-12 mt-2 mb-2">
  				{ candidate ? (<h1>Candidato: {candidate.name} {candidate.lastname}</h1>	 ) : ( null)

  				}

  				 
  				
  			</Col>
  			
  			<Col className="col-12">
  				
  				<Cuestionario    /> 			
  					
  			</Col>
  			<Col className='text-center mt-2 mb-2'>
  				
  				{	candidateAnswer.answers.length > 0 ? (
  						<Button variant="primary" onClick={saveAnswers}> Guardar respuestas </Button>		
  					) : (
  						null
  					)

  				}	
  					
  			</Col>
  		</Row>
	    
	 );

};

export default NewCuestionario