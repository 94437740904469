import React, { useState } from 'react'


import Button from 'react-bootstrap/Button'
import { Container, Col, Row, Collapse, Card,Modal, ButtonGroup} from 'react-bootstrap'
// import { TwitterMentionButton } from 'react-twitter-embed'
import { useSelector } from 'react-redux'


import  NewCuestionario  from '../../cuestionarios/users/new'
import  ShowCuestionario  from '../../cuestionarios/candidates/show'
import  CandidatoCard  from './candidato_card'

import './invita.css';

const Invita = ({setKey, handleSelect}) => {
  // console.log(setKey);
  const candidatos   = useSelector(state => state.appData.candidatos)

  // const [openMex, setOpenMex] = useState(false);
  // const [openCoa, setOpenCoa] = useState(false);

  // const handleOpenMex = () => {
  //   setOpenMex(!openMex);
  //   setOpenCoa(false);
  // }
  // const handleOpenCoa = () => {
  //   setOpenCoa(!openCoa);
  //   setOpenMex(false);
  // }


  const [showCuestionarioModal, setShowCuestionarioModal] = useState(false);
  const [showCandidateCuestionarioModal, setShowCandidateCuestionarioModal] = useState(false);

  const [candidatoSelected, setCandidatoSelected] = useState({});

  const handleCloseCandidateCuestionarioModal = () => setShowCandidateCuestionarioModal(false);
  const handleShowCandidateCuestionarioModal = (candidato) => {
    setCandidatoSelected(candidato)
    setShowCandidateCuestionarioModal(true);
  }
  

  const handleCloseCuestionarioModal = () => setShowCuestionarioModal(false);
  const handleShowCuestionarioModal = () => setShowCuestionarioModal(true);

  const handleInfoEstado = (taEstado) => {
    setKey('elecciones');
    handleSelect(taEstado);
  }


  const getTwitterUserName = (url) => {
    if (!url) return null;
    const match = url.match(/^https?:\/\/(www\.)?twitter.com\/@?(?<handle>\w+)/);
    return match?.groups?.handle ? `@${match.groups.handle}` : null;
  }

  return (
    <Container>
        <br /> <br />
        <div className='header-invita'>
            <h1>ELECCIONES LOCALES 2023</h1>
        </div>
      <br /> <br />
      <Row>
        <Col>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
          <Row>
            <Col md={12}>
              <div class="image-container img-estado">
                <img src="/static/inicio/BotonEdo_Coahuila.png" className='img-card img-fluid image-overlay' />
                <div class="middle">
                  <a href='#info-coahuila'>
                  <Button className='button-voto' size="lg" active onClick={() => handleInfoEstado('coahuilaTab')} >
                    {/* <a href='#info-coahuila' onClick={() => handleInfoEstado('coahuilaTab')}> */}
                      Conoce tu estado
                    {/* </a> */}
                  </Button>
                  </a>
                </div>
              </div>
            </Col>
            {candidatos.map((candidato,index)=>{
              if(candidato.estado==1  ){
                return(
                  <Col md={6} sm={12} className='mb-3'>
                    <CandidatoCard candidato={candidato} showCuestionarioModal={handleShowCandidateCuestionarioModal} setKey={setKey} handleSelect={handleSelect} urlCard={'#coahuila-'+index.toString()}/>
                  </Col>
                )
              }
            })}
          </Row>
          </div>
        </Col>
        <Col>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
          <Row>
            <Col md={12}>
            <div class="image-container img-estado">
              <img src="/static/inicio/BotonEdo_EdoMex.png" className='img-card img-fluid image-overlay' />
              <div class="middle">
                <Button className='button-voto' size="lg" active onClick={() => handleInfoEstado('edoMexTab')} href='#info-edomex'>
                  Conoce tu estado
                </Button>
              </div>
            </div>
            </Col>
            {candidatos.map((candidato,index)=>{
              if(candidato.estado==0  ){
                return(
                  <Col md={6} sm={12} className='mb-3'>
                    <CandidatoCard candidato={candidato} showCuestionarioModal={handleShowCandidateCuestionarioModal} setKey={setKey} handleSelect={handleSelect} urlCard={'#edomex-'+index.toString()}/>
                  </Col>
                )
              }
            })}
          </Row>
          </div>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <Container className='center-banner'>
              <a href='https://www.ieem.org.mx/' target='_blank' >
                <img src='/static/inicio/ieem.jpeg' className='img-banner-ieem'/>
              </a>
            </Container>
          </div>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col className="text-center mb-4 mt-4">

          <Button className='button-voto' size="lg" active onClick={handleShowCuestionarioModal}>
            Contesta la encuesta y compara tus respuestas con las de tus candidatas y candidatos
          </Button>
          
        </Col>
      </Row>

      <br />


      <Modal show={showCuestionarioModal} onHide={handleCloseCuestionarioModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
        
        <Modal.Body> 
          <NewCuestionario />
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="danger" onClick={handleCloseCuestionarioModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showCandidateCuestionarioModal} onHide={handleCloseCandidateCuestionarioModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
        
        <Modal.Body>
          <ShowCuestionario candidato= {candidatoSelected}/>  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCandidateCuestionarioModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>




    </Container>
  )
}

export default Invita
