import React from 'react'
import { Form,Row, Col,Button,Card,Badge } from 'react-bootstrap'

//import cuestionarioJSON from './cuestionario_de_usuario.json';
import  { useEffect, useState } from "react"; 



import cuestionarioJSONedoMex from '../../../../cuestionarios_json/cuestionario_de_usuario_edo_mex.json';
import cuestionarioJSONcoahuila from '../../../../cuestionarios_json/cuestionario_de_usuario_coahuila.json';

import bloques from '../../../../cuestionarios_json/bloques.json';



import styles from './show.css';



function ShowCuestionario(props){

	const [cuestionario, setCuestionario] = useState( [] );
	const [bloque, setBloque] = useState( bloques[0].blockID );


	useEffect(() => {

		if(props.candidato.estado==0){
	          setCuestionario(cuestionarioJSONedoMex)
	  }else{
	          setCuestionario(cuestionarioJSONcoahuila)
	  } 


  }, [cuestionario]) //currentAnswer



  function ShowDropDownAnswer(props){
    console.log(props)
    var respLabel="Sin respuesta"

    var answersFound = props.candidato.answers.find(answer => answer.id === props.question.id);

    console.log(answersFound)
    
    if(answersFound.value !== -1){
        const opc= props.question.attributes.choices.find(choice => choice.value === answersFound.value);
        if(opc.label === 'Otro'){
          respLabel = opc.label+': '+answersFound.otro_value;
        }else{
          respLabel = opc.label
        }
    }else{
      respLabel="No ha respondido"
    }


        
        if(answersFound.value !== -1){
          return (

                 <div className='candidate-answer mt-2 mb-2 text-center'>
                    <span className='success-answer'>
                      {respLabel}
                      </span>
                  </div>
          );  
        }else{
          return (
            <div>
                 <div className='candidate-answer mt-2 mb-2 text-center'>
                    <span className='danger-answer'>
                      {respLabel}
                      </span>
                  </div>
            </div>
            
            
          );
        }
      
    

  }


  function ShowMultipleAnswer(props){
    var respLabel="Sin respuesta"

    var answersFound = props.candidato.answers.find(answer => answer.id === props.question.id);
    

    //<pre>{JSON.stringify(props.candidate.answers, null, 2)}</pre>
   


      var checkAll = true
      answersFound.subquestions.forEach(subanswer => {
        if(subanswer.value==="" || subanswer.value=== -1)
          checkAll = false
        
      })
      //console.log(answersFound);


      

      return(
        <div className='candidate-answer mt-2 mb-2 '>
          {  checkAll ? (
            <div className='success-answer'>
              { answersFound.subquestions.map((subanswer,index)=>{
                  const subquestion= props.question.attributes.subquestions.find(sub => sub.id === subanswer.id);
                  const opc= subquestion.attributes.choices.find(choice => choice.value === subanswer.value);
                  console.log(props.question.attributes.showSubquestionLabel);
                  console.log(subquestion.attributes);
                  return (
                      <div >
                        {/* { props.question.attributes.showSubquestionLabel ? ( */}
                        { subquestion.attributes ? (
                            <div className='mt-' > {subquestion.attributes.label}:  <b>{opc.label+"\n"}</b></div>
                          ) : (
                             <span> {index+1}- {opc.label+"\n"}</span>
                          )

                        }

                          
                      </div>
                  ) 
                })  
                  
              }



            </div>  
            ) : (
            <span className='danger-answer'>
              No ha respondido
            </span>
            )
          }
                     
        </div>
      )

    

  }


  function ShowPriorityAnswer(props){
    var respLabel="Sin respuesta"
    var answersFound = props.candidato.answers.find(answer => answer.id === props.question.id);

   

      var checkAll = true
      answersFound.subquestions.forEach(subanswer => {
        if(subanswer.value==="" || subanswer.value=== -1)
          checkAll = false
        
      })

      return(
        <div className='candidate-answer mt-2 mb-2'>
          {  checkAll ? (
            <div className='success-answer'>
              {console.log(answersFound)}
              { answersFound.subquestions.map((subanswer,index)=>{
                const subquestion= props.question.attributes.subquestions.find(sub => sub.id === subanswer.id);
                const opc= props.question.attributes.choices.find(choice => choice.value === subanswer.value);
                console.log(subanswer);   
                return (
                      opc.label === 'Otro' ?
                      <div >
                           {subquestion.label}: {opc.label+': '+subanswer.otro_value}
                      </div>
                      :
                      <div >
                           {subquestion.label}: {opc.label}
                      </div>
                  ) 
                })  
                  
              }



            </div>  
            ) : (
            <span className='danger-answer'>
              No ha respondido
            </span>
            )
          }
                     
        </div>
      )



    

  }


function ShowAnswer(props) {

    switch(props.question.attributes.type){
    case "dropdown" :
      return(
        <ShowDropDownAnswer candidato={props.candidato} question={props.question}/>
      )

    case "multiple" :
      return(
        <ShowMultipleAnswer candidato={props.candidato} question={props.question}/>
      ) 
      
    case "priority" :
      return(
        <ShowPriorityAnswer candidato={props.candidato} question={props.question}/>
      )
      
      
    }

  }





function WithAnswers(candidato) {
  if(candidato.candidato ===0){
    return (
          <Badge bg="danger" className='candidate-resp-badge-size mt-2'>
             El candidato no ha respondido el cuestionario
          </Badge>
        );
  }

  
}




  function ShowAnswers(props) {

    

      return (
        cuestionario.map((question,index)=>{
          console.log(question);
          if (question.name=== "question" && question.attributes.blockID === bloque && question.id !== 'preg00024' && question.id !== 'preg00025') 
          	return (
          		<Col xs={12} className='shadow mt-2 mb-2' >
			        	<Row>
					        <Col className='col-12'>
					          {question.attributes.label}
					        </Col> 
					        <Col className='col-12'>
					        	<ShowAnswer  candidato={props.candidato} question={question}   />
					        </Col>
					    	</Row>
			        </Col>
			       )         
        

        })
        
      );
    
    
}












 const hanldeChangeBlockClick = (blockID) => {  
   setBloque(blockID) 
  }



  
  return (
    <Row>
      <Col xs={12} className='text-center'>
        <h1> {props.candidato.name} {props.candidato.lastname}</h1>
        <WithAnswers  candidato={props.candidato.with_answers} />
      </Col>

      <Col xs={12} className='mt-4'>
        <Row>
        	<Col xs={12} lg={3} className=''>
		        {bloques.map((bloque,index)=>{
		        	return  <Button className='button-voto full-button-block mb-3' variant="primary" onClick={() => hanldeChangeBlockClick(bloque.blockID)} >{bloque.name}</Button>
		        })}
		      </Col>
		      <Col xs={4} lg={9} className='text-center '>
		      	<Row>
		        	<ShowAnswers candidato={props.candidato}/>
		        </Row>
		      </Col>
        </Row>
      </Col>

      
    </Row>
  )
}



export default ShowCuestionario
