import React from 'react'
import {Badge,Row,Col } from 'react-bootstrap'
import  { useEffect, useState } from "react";

import styles from './new.css';


//import cuestionarioJSON from '../../../../cuestionarios_json/cuestionario_de_usuario.json';




const CandidateCard = (props) => {




  function ShowDropDownAnswer(){
    
    var respLabel="Sin respuesta"

    var answersFound = props.candidate.answers.find(answer => answer.id === props.question.id);

    console.log(answersFound)
    if(answersFound.value !== -1){
        const opc= props.question.attributes.choices.find(choice => choice.value === answersFound.value);
        if(opc.label === 'Otro'){
          respLabel = opc.label+': '+answersFound.otro_value;
        }else{
          respLabel = opc.label
        }
    }else{
      respLabel="No ha respondido"
    }


      if(answersFound.visible){
        if(answersFound.value !== -1){
          return (
          
                 <div className='candidate-answer mt-4 text-center'>
                    <span className='success-answer'>
                      {respLabel}
                      </span>
                  </div>
          );  
        }else{
          return (
            <div>
                 <div className='candidate-answer mt-4 text-center'>
                    <span className='danger-answer'>
                      {respLabel}
                      </span>
                  </div>
            </div>
            
            
          );
        }
      }
    

  }


  function ShowMultipleAnswer(){
    var respLabel="Sin respuesta"

    var answersFound = props.candidate.answers.find(answer => answer.id === props.question.id);
    

    //<pre>{JSON.stringify(props.candidate.answers, null, 2)}</pre>
    if(answersFound.visible){


      var checkAll = true
      answersFound.subquestions.forEach(subanswer => {
        if(subanswer.value==="" || subanswer.value=== -1)
          checkAll = false
        
      })



      

      return(
        <div className='candidate-answer mt-4 '>
          {  checkAll ? (
            <div className='success-answer'>
              { answersFound.subquestions.map((subanswer,index)=>{
                  const subquestion= props.question.attributes.subquestions.find(sub => sub.id === subanswer.id);
                  const opc= subquestion.attributes.choices.find(choice => choice.value === subanswer.value);
                   
                  return (
                      <div >
                        { props.question.attributes.showSubquestionLabel ? (
                            <div className='mt-2' > {subquestion.attributes.label}:  <b>{opc.label+"\n"}</b></div>
                          ) : (
                             <span> {index+1}- {opc.label+"\n"}</span>
                          )

                        }

                          
                      </div>
                  ) 
                })  
                  
              }



            </div>  
            ) : (
            <span className='danger-answer'>
              No ha respondido
            </span>
            )
          }
                     
        </div>
      )

    }

  }

  function ShowPriorityAnswer(){
    var respLabel="Sin respuesta"
    var answersFound = props.candidate.answers.find(answer => answer.id === props.question.id);

    if(answersFound.visible){

      var checkAll = true
      answersFound.subquestions.forEach(subanswer => {
        if(subanswer.value==="" || subanswer.value=== -1)
          checkAll = false
        
      })

      return(
        <div className='candidate-answer mt-4 '>
          {  checkAll ? (
            <div className='success-answer'>
              { answersFound.subquestions.map((subanswer,index)=>{
                const subquestion= props.question.attributes.subquestions.find(sub => sub.id === subanswer.id);
                const opc= props.question.attributes.choices.find(choice => choice.value === subanswer.value);
                console.log(opc);
                return (
                  
                    opc.label === 'Otro' ?
                      <div >
                        {subquestion.label}: {opc.label+': '+subanswer.otro_value}
                      </div>
                    :  
                      <div >
                           {subquestion.label}: {opc.label}
                      </div>
                  ) 
                })  
                  
              }



            </div>  
            ) : (
            <span className='danger-answer'>
              No ha respondido
            </span>
            )
          }
                     
        </div>
      )




      /*return(
        <Row>
          
        {answersFound.subquestions.map((subanswer,index)=>{
            if(subanswer.value==="" || subanswer.value=== -1){
              return (
                <Col  xs={12}>  
                  <Badge bg="danger" className='candidate-resp-badge-size mt-2'>
                      No ha respondido
                  </Badge>
                </Col>)
            }else{
              const subquestion= props.question.attributes.subquestions.find(sub => sub.id === subanswer.id);

               const opc= props.question.attributes.choices.find(choice => choice.value === subanswer.value);
               
              return (
                <Col  xs={12}> 

                  <Badge bg="success" className='candidate-resp-badge-size mt-2'>
                       {subquestion.label}: {opc.label}
                  </Badge>
                </Col>)
            }
        })}
        </Row>
      )*/
    }

  }



  function ShowAnswer(props) {

    if (props.candidate.with_answers == 1) {  

      switch(props.question.attributes.type){
        case "dropdown" :
          return(
            <ShowDropDownAnswer answer={props.answer} question={props.question}/>
          )

        case "multiple" :
          return(
            <ShowMultipleAnswer answer={props.answer} question={props.question}/>
          )
        case "priority" :
          return(
            <ShowPriorityAnswer answer={props.answer} question={props.question}/>
          ) 
        } 

      
    }else{
      return (
        <div> 
          <Badge bg="danger" className='candidate-resp-badge-size mt-2'>
             Sin respuestas
          </Badge>
        </div>
      );
    }



    /*switch(props.question.attributes.type){
    case "dropdown" :
      return(
        <ShowDropDownAnswer answer={props.answer} question={props.question}/>
      )

    case "multiple" :
      return(
        <ShowMultipleAnswer answer={props.answer} question={props.question}/>
      )
    case "priority" :
      return(
        <ShowPriorityAnswer answer={props.answer} question={props.question}/>
      ) 
    }*/
  }

  function ShowAnswers(props) {




    if (props.candidate.with_answers == 1) {   

      return (
        props.candidate.answers.map((answer,index)=>{                 
          return <ShowAnswer answer={answer} question={props.question}/>
        })
        
      );
    }else{
      return (
        <div> 
          <Badge bg="danger" className='candidate-resp-badge-size mt-2'>
             Sin respuestas
          </Badge>
        </div>
      );
    }
    
  }

  //<ShowAnswer candidate={props.candidate} question={props.question}/>
  //<pre>{JSON.stringify(props.candidate.answers, null, 2)}</pre>

  return (
    parseInt(props.candidate.estado) === 0 ?
      <div className=' form-control shadow mt-1 candidate-row'>
        <Row>
          <Col xs={2}></Col>
          <Col xs={8}>
            <img  class='img-fluid candidate-img' src={props.candidate.image_url} alt=' '  />
          </Col> 
          <Col xs={2}></Col>
          {/* <Col xxl={10} xl={9} lg={7} md={9} sm={8} xs={7} > */}
          <Col xs={12}>
            <Row>
              <Col xxl={12} className='text-center'>
                <div className='text-center'>{props.candidate.name+' '+props.candidate.lastname }</div>
              </Col> 
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className=''>
                <Row>
                  <Col xs={1}></Col>
                  <Col  xs={10}  > <ShowAnswer candidate={props.candidate} question={props.question}/> </Col>
                  <Col xs={1}></Col>
                </Row>
              </Col>
            </Row>
          </Col> 
        </Row>
      </div>
    :
      <div className=' form-control shadow mt-1 candidate-row'>
      <Row>
          <Col xxl={2}>
          <img  class='img-fluid candidate-img' src={props.candidate.image_url} alt=' '  />
        </Col> 
        <Col xxl={10} xl={9} lg={7} md={9} sm={8} xs={7} >
          <Row>
            <Col xxl={12}>
              <div className='text-center'>{props.candidate.name+' '+props.candidate.lastname }</div>
            </Col> 
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className=''>
              <Row>
                <Col  xs={{ span: 4, offset: 4 }}> <ShowAnswer candidate={props.candidate} question={props.question}/> </Col>
              </Row>
            </Col>
          </Row>
        </Col> 
      </Row>
    </div>
  );




};

export default CandidateCard