import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './index.css';


const index = () => {
  return (
    
      <Row className="py-4 row-botom" id="bottom-section">
        <div id='bottom'></div>
        <Col>
          <a href='https://ubicatucasilla.ine.mx/' target="_blank">
            <div className='col-center'>
              <img className='img-bottom' src='/static/bottom_section/Boton_casilla.png' alt='ubica tu casilla' />
            </div>
          </a>
        </Col>
        {/* <Col >
          <img className='carousel-center' src='/static/bottom_section/audiovisuales.png' alt='First slide' />
        </Col> */}
        <Col >
          <a href='/diviertete-aprende'>
            <div className='col-center'>
              <img className='img-bottom' src='/static/bottom_section/Boton_diviertete.png' alt='diviertete y aprende' />
            </div>
          </a>
        </Col>
        <Col >
          <a href='https://www.ine.mx/voto-y-elecciones/elecciones-2023/' target="_blank">
            <div className='col-center' >
              <img className='img-bottom' src='/static/bottom_section/Boton_calendario.png' alt='calendario electoral' />
            </div>
          </a>
        </Col>
      </Row>
      
    
  )
}

export default index




