import React from 'react'
import Container from 'react-bootstrap/Container';
import MenuPrincipal from './tabs';

const index = () => {
  return (
    <Container>
        <MenuPrincipal />
      {/* <Principal className='sectionScroll' />
      <ResultTema className='sectionScroll' />
      <ResultEstado className='sectionScroll' />
      <ResultEdades className='sectionScroll' />
      <Newsletter className='sectionScroll' />
      <Footer/> */}
    </Container>
  )
}

export default index
