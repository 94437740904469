import React from 'react'
import { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
// import { saveAs } from "file-saver";
import Comite from './comite';
import Equipo from './equipo';
import './quienes.css';

const QuienesSomos = () => {

//   const [showCuestionario, setShowCuestionario] = useState(false);
//   const [cuestionario, setCuestionario] = useState(null);

  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState(null);

  const handleCloseModal= () =>{
    setShowFile(false);
  }

//   const handleDownload = (url, nameFile)=>{
//     saveAs(url, nameFile);
//   }

  const handleModal = (fileType) =>{
    if(fileType === 'coahuila'){
        setFile('static/cuestionarios/CuestionarioVotoInformadoCoahuila2023.pdf');
        setShowFile(true);
    }else{
        if(fileType === 'edomex'){
            setFile('static/cuestionarios/CuestionarioVotoInformadoEdoMex2023.pdf');
            setShowFile(true);
        }
    }
    if(fileType === 'oficioVeda'){
        setFile('static/oficio/INE-DJ-6175-2023.pdf');
        setShowFile(true);
    }
    if(fileType === 'informe2023'){
        setFile('static/informes/Informe_de_Resultados_Voto_Informado_2023.pdf');
        setShowFile(true);
    }
  }

  return (
    <React.Fragment>
    <Container>
        <Row>
            <Col xs={12} className='pb-5 pt-3'>
                <h1>INICIATIVA</h1>
            </Col>
            <Col sm={6} xs={12}>
                <img src='static/logos/LogoVIUNAM_vertical.png' className='img-iniciativa'></img>
            </Col>
            <Col sm={6} xs={12}>
                <h3>¿QUÉ ES VOTO INFORMADO? </h3>
                <br/>
                <p className="text-justification">
                Voto Informado es una iniciativa de la Facultad de Ciencias Políticas y Sociales de la UNAM en conjunto
                con el Instituto Nacional Electoral, académicas, académicos y organizaciones de la sociedad civil que,
                desde el 2015, busca promover la participación ciudadana y fortalecer la cultura democrática del país
                proporcionando información relevante y veraz, además de formación cívica permanente a la ciudadanía.
                <br/> <br/>

                Con este proyecto, la UNAM reitera su compromiso y voluntad para contribuir en la búsqueda de soluciones
                a los retos que enfrentamos como país.
                </p>
            </Col>
        </Row>
    </Container>
    <div className='full-width'>
        <Row className='row-mision-vision'>
            <Col sm={3} xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>OBJETIVO</h5>
                    </div>
                    <br/>
                    <p className='text-justification'>
                        Que la ciudadanía tenga elementos para analizar, dialogar y evaluar las distintas ofertas
                        políticas, acercando información sobre las posturas, trayectorias, opiniones, propuestas
                        e ideas de sus candidatos de forma comparada, equitativa, objetiva e imparcial.
                    </p>
                </div>
            </Col>
            <Col sm={3} xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>MISIÓN</h5>
                    </div>
                    <br/>
                    <p className='text-justification'>
                        Ser la principal herramienta de consulta que brinde información útil y oportuna a la
                        ciudadanía sobre sus candidatas y candidatos a puestos de elección popular durante los
                        procesos electorales locales y federales en México.
                    </p>
                </div>
            </Col>
            <Col sm={3} xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>VISIÓN</h5>
                    </div>
                    <br/>
                    <p className='text-justification'>
                        Promover entre la ciudadanía el ejercicio del voto informado como la mejor práctica
                        para elegir a sus representantes populares.
                    </p>
                </div>
            </Col>
            <Col sm={3} xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>VALORES</h5>
                    </div>
                    <ul>
                        <li>Incentivar la participación cívica</li>
                        <li>Brindar información veraz, transparente y objetiva</li>
                        <li>Fomentar la cultura de la legalidad</li>
                        <li>Salvaguardar los derechos y libertades</li>
                        <li>Dar formación cívica permanente</li>
                    </ul>
                </div>
            </Col>
        </Row>
    </div>
    <Container>
        <div className='head-center pt-5'>
            <h3>¿CÓMO LO HACEMOS?</h3>
        </div>
        <div className='head-center py-4'>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-clipboard2-data" viewBox="0 0 16 16">
                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"/>
                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"/>
                <path d="M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z"/>
            </svg>
        </div>
        <p className='text-justification'>
            El instrumento principal consiste en la realización de un estudio de opinión censal
            a candidatos(as) a puestos de elección popular sobre temas de coyuntura, principales
            problemas del país, economía, ejercicio del presupuesto, propuestas y trayectoria de
            cada candidata(o), entre otros, para que la ciudadanía pueda consultar esa información
            libremente y emitir un voto más razonado. Las respuestas, así como información
            relevante sobre el proceso electoral y los partidos políticos se sube a nuestra plataforma
            en línea y permanece incluso durante el periodo de veda electoral <a href='#' onClick={()=> handleModal('oficioVeda')}>Oficio de Opinión sobre la operación del portal</a>
        </p>
    </Container>
    <div className='full-width'>
        
        <div className='head-center pt-5'>
            <h1>CUESTIONARIOS</h1>
        </div>
        <Row className='row-mision-vision'>
            <Col xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>COAHUILA</h5>
                    </div>
                    <div className='head-center'>
                        <Button variant='ligth' onClick={() => handleModal('coahuila')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                            </svg>
                        </Button>
                    </div>
                </div>
            </Col>
            <Col xs={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='head-center pb-2'>
                        <h5>ESTADO DE MÉXICO</h5>
                    </div>
                    <div className='head-center'>
                        <Button variant='ligth' onClick={() => handleModal('edomex')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                            </svg>
                        </Button>
                    </div>
                </div>
            </Col>
            <Col xs={12}>
                <div className='head-center pb-2'>
                    <h1>INFORMES</h1>
                </div>
            </Col>
            <Col xs={12} className='d-flex justify-content-center'>
                <div className="shadow-lg p-3 mb-5 bg-white rounded shadow-informes">
                    <div className='head-center pb-2'>
                        <h5>INFORME 2023</h5>
                    </div>
                    <div className='head-center'>
                        <Button variant='ligth' onClick={() => handleModal('informe2023')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                            </svg>
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>    
    </div>
    <Comite />
    <Equipo />
    <Modal show={showFile} onHide={handleCloseModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
        <Modal.Body>
        <div className='img-center'>
            <iframe src={file} 
                    width="1000" 
                    height="690">
            </iframe>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>
    </React.Fragment>
  )
}

export default QuienesSomos
