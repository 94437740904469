import React from 'react'
import {Badge,Row,Col,Card,Nav,Tabs,Tab } from 'react-bootstrap'
// import  { useEffect, useState } from "react";

import styles from './index.css';




function SocialNetwork (props){
	const social = props.social
	if(social.url!=="")
	switch(social.type){
		case "facebook" :
			return(
				<a href={social.url}>
					    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16" class='text-right'>
					    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
					    </svg>
				</a>
		)
		case "instagram":
			return(
				<a href={social.url}>
			                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
			                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
			                </svg>
			                </a>
			)
		case "twitter":
			return(
				<a href={social.url}>
			                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
			                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
			                </svg>
			    </a>
			)
		case "linkedin":
			return(
				<a href={social.url}>
			                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
			                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
			                </svg>
			                </a>
			)
		case "email":
			return(
				<a href={"mailto:"+social.url}  >
	                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
        					  <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
        					  <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
        					</svg>
	                </a>
			)
		case "whats":
			return(
				<a href={"tel:"+social.url}>
	                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                  </svg>
	               	</a> 
			)
		case "phone":
			return(
				<a href={"tel:"+social.url}>
	                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
        					  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
        					</svg>
	               	</a> 
			)

	}


}

const CandidateCard = (props) => {


  return (
    <Card className='shadow'>
    	<Card.Header as="h4" className='text-center '>{props.candidate.name} {props.candidate.lastname}</Card.Header>
            <Card.Body>
            	<Row>
			     	<Col  xs={12} md={3}>
			        	<img  class='img-fluid' src={props.candidate.image_url} alt=' '  />
			        	<div className='text-center mt-2'>
			        		<a href='#' onClick={() => props.openPlatf(props.candidate.platform)}>Plataforma electoral</a>
			        	</div>
			      	</Col>
			      	<Col xs={9}>
			      		<Tabs defaultActiveKey={"profile"+props.keyy} id={"tab-candidate"+props.keyy} className="mb-3">
					      <Tab eventKey={"profile"+props.keyy} title="Perfil">
					      	<Row className='mt-2'>
										<Col className='col-12 info-candidato'>
											<p className='info-data'>
												{props.candidate.semblanza}
											</p>
										</Col>
			              <Col className='col-6 info-candidato'>
			                                    <div class=''>
			                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-heart-fill info-icon" viewBox="0 0 16 16">
			                                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
			                                        </svg>
			                                        <label class='info-label'> Fecha de nacimiento </label>
			                                      </div>

			                                      <div class='info-data'>{props.candidate.bithdate}</div>
			              </Col>
										<Col className='col-6 info-candidato'>
			                                      <div class=''>
			                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill info-icon" viewBox="0 0 16 16">
			                                          <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
			                                        </svg>
			                                        <label class='info-label'> Edad </label>
			                                      </div>

			                                      <div class='info-data'>{new Date().getFullYear() - props.candidate.bithdate.slice(-4)}</div>
			              </Col>
			              <Col className='col-12 info-candidato'>
			                                      <div class=''>
			                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill info-icon" viewBox="0 0 16 16">
			                                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
			                                        </svg>
			                                        <label class='info-label'> Lugar de origen </label>
			                                      </div>

			                    			<div class='info-data'> {props.candidate.birthplace}</div>
			              </Col>
			              <Col className='col-6 info-candidato'>
			                                      <div class=''>
			                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag-fill info-icon" viewBox="0 0 16 16">
			                                          <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
			                                        </svg>
			                                        <label class='info-label'> Partido </label>
			                                      </div>

			                                      <div class='info-data'>{props.candidate.partido} </div>
			              </Col>
										<Col className='col-6 info-candidato'>
			                                      <div class=''>
			                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe2 info-icon" viewBox="0 0 16 16">
			                                          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
			                                        </svg>
			                                        <label class='info-label'> Fuente </label>
			                                      </div>

			                                      <div class='info-data'>
			                                      	{JSON.parse(props.candidate.personal_url).map((fuente,index)=>{
														                    if(fuente.type=="url"){
														                      return (
														                      	 <a href={fuente.value} target={'_blank'}>{fuente.value}</a>
											                                    )
														                    }else{
														                    	return (
														                      	 <span> {fuente.value}</span>
											                                    )
														                    	
														                    }
														                 })}
			                                      </div>
			             	</Col>
			             	
			            </Row> 
					      </Tab>
					      <Tab eventKey={"estudios"+props.keyy} title="Estudios">
					      	<Row>
			               			{JSON.parse(props.candidate.trayectorias).estudios.map((estudio,index)=>{
					            		return(
					            			<Col className='col-12 info-candidato mt-2'>
						            			<Row>
													<Col className='col-1 '>
							                          <div className='numberCircle'>{index+1}</div>
							                        </Col>
							                        <Col className='col-10'><div class='info-data'>{estudio}</div></Col>			                  	
												</Row>
											</Col>
										)
									})}
			               	</Row>
					      </Tab>
					      <Tab eventKey={"profesional"+props.keyy} title="Trayectoria profesional" >
					      	<Row>
			              {JSON.parse(props.candidate.trayectorias).profesional.map((profesional,index)=>{
					            return(
					            	<Col className='col-12 info-candidato mt-2'>
						            	<Row>
														<Col className='col-1 '>
							                <div className='numberCircle'>{index+1}</div>
							              </Col>
							              <Col className='col-10'><div class='info-data'>{profesional}</div></Col>			                  	
													</Row>
												</Col>
											)
										})}
			               	</Row>  
					      </Tab>
					      <Tab eventKey={"politica"+props.keyy} title="Trayectoria política" >
					      	<Row>
			               			{JSON.parse(props.candidate.trayectorias).politica.map((politica,index)=>{
					            		return(
					            			<Col className='col-12 info-candidato mt-2'>
						            			<Row>
													<Col className='col-1 '>
							                          <div className='numberCircle'>{index+1}</div>
							                        </Col>
							                        <Col className='col-10'><div class='info-data'>{politica}</div></Col>			                  	
												</Row>
											</Col>
										)
									})}
			               	</Row>  
					      </Tab>
					      <Tab eventKey={"civil"+props.keyy} title="Trayectoria en sociedad civil" >
					       	<Row>
				            {JSON.parse(props.candidate.trayectorias).civil.map((civil,index)=>{
					            		return(
					            			<Col className='col-12 info-candidato mt-2'>
						            			<Row>
													<Col className='col-1 '>
							                          <div className='numberCircle'>{index+1}</div>
							                        </Col>
							                        <Col className='col-10'><div class='info-data'>{civil}</div></Col>			                  	
												</Row>
											</Col>
										)
										})}
									</Row> 
					      </Tab>
					      
					    </Tabs>
					</Col>
				</Row>

				<Row>
					
					<Col className='col-12 info-candidato'>
						



			             		
			             			<Row>
			             				<Col xs={12}>
			             					<Row>
			             						<Col xs={12} className='text-center mt-2 mb-2'>
			             							ASPECTOS QUE DESTACA DE SU TRAYECTORIA
			             						</Col>

			             						{props.candidate.answers[ props.candidate.answers.length-2] ? 
			             								(
			             									<Col xs={12} className=''>
			             										{props.candidate.answers[ props.candidate.answers.length-2 ].subquestions.map((subquestion,index)=>{
															          return(
															          	<Row className='mt-2'>
																						<Col className='col-1 '>
															                <div className='numberCircle'>{index+1}</div>
															              </Col>
															              <Col className='col-10'>

															              	<div class='info-data'>
																              	{ subquestion.value !== "" ? (
							             									 			<q>
							             									 				{subquestion.value}	
							             									 			</q>
							             									 			) : (
							             									 				<h5 className=''>
							             									 					<Badge bg="warning">Sin mensaje</Badge>	
							             									 				</h5>
							             									 			)
							             									 		}
															              		
															              	</div>
															              </Col>			                  	
																					</Row>  			

																				)
																			})}
						             								
						             						</Col>	 	
			             									 	

			             								) : (
			             									<h5 className='text-center'>
			             										<Badge bg="danger">No ha respondido</Badge>
			             									</h5>
			             								)  
			             						}

			             						
			             					</Row>
			             				</Col>
			             				<Col xs={12}>
			             					<Row>
			             						<Col xs={12} className='text-center mt-2 mb-2'>
			             							MENSAJE A LA CIUDADANÍA
			             						</Col>
			             						<Col xs={12} className=''>
			             							<blockquote>
			             								{props.candidate.answers[ props.candidate.answers.length-1] ? (

			             									 	<div>
			             									 		{ props.candidate.answers[ props.candidate.answers.length-1 ].subquestions[0].value!== "" ? (
			             									 			<q>
			             									 				{props.candidate.answers[ props.candidate.answers.length-1 ].subquestions[0].value}	
			             									 			</q>
			             									 			) : (
			             									 				<h5 className='text-center'>
			             									 					<Badge bg="warning">Sin mensaje</Badge>	
			             									 				</h5>
			             									 			)
			             									 		}


			             									 		
			             									 	</div>
			             									 	

			             									) : (
			             										<h5 className='text-center'>
			             											<Badge bg="danger">No ha respondido</Badge>
			             										</h5>
			             									)  
			             								}



			             								
																	
			             							</blockquote>
			             						</Col>
			             					</Row>	
			             				</Col>
			             			</Row>

			             			

			             		

			             	</Col>



				</Row>

        </Card.Body>
            
            <Card.Footer className="text-mute text-center">
            	
            	<Row>
	            	{JSON.parse(props.candidate.social).map((fuente,index)=>{
	            		return(<Col>
											<SocialNetwork social={fuente}/>					                  	
										</Col>)
									})}
							</Row>
            </Card.Footer>
        </Card>
  );




};

export default CandidateCard