import React from 'react'
import { Button, Carousel, Container, Row, Col } from 'react-bootstrap';
// import { HashLink as MyLink } from 'react-router-hash-link';
import '../carrusel/carrusel.css';

const Carrusel = ({setKey, openForo}) => {
  return (
      <div className='carousel'>
        <Carousel variant='dark'>
		<Carousel.Item>
				<a href='https://2023.prepcoah.mx/inicio' target='_blank'>
					<img
						className='carousel-center'
						src='/static/banner/Coahuila.jpg'
						alt='Prep Coahuila'
						//loading='lazy'
					/>
				</a>
			</Carousel.Item>
			<Carousel.Item>
				<a href='https://prep.ieem.org.mx/#/' target='_blank'>
					<img
						className='carousel-center'
						src='/static/banner/EdoMex.jpg'
						alt='Prep slide'
						//loading='lazy'
					/>
				</a>
			</Carousel.Item>
			<Carousel.Item>
				<a href='#' onClick={() => setKey('voto_extranjero')}>
					<img
						className='carousel-center'
						src='/static/banner/Banner_3.png'
						alt='First slide'
					/>
				</a>
			</Carousel.Item>
			<Carousel.Item>
				<a href='#' onClick={() => setKey('elecciones')}>
					<img
						className='carousel-center'
						src='/static/banner/Banner_2.png'
						alt='Second slide'
						//loading='lazy'
					/>
				</a>
			</Carousel.Item>
			<Carousel.Item>
				{/* <MyLink to='/analisis'> */}
					<img
						className='carousel-center'
						src='/static/banner/BannerAjustado_5.jpg'
						alt='Third slide'
						//loading='lazy'
					/>
				{/* </MyLink> */}
			</Carousel.Item>
			<Carousel.Item>
				<a href='#' onClick={() => setKey('entrevistas')}>
					<img
						className='carousel-center'
						src='/static/banner/Banner_4.jpg'
						alt='Fourth slide'
						//loading='lazy'
					/>
				</a>
			</Carousel.Item>
			<Carousel.Item>
				<a href='https://www.ine.mx/red-ciudadana-de-difusion-y-promocion-de-la-cultura-democratica/' target='_blank'>
					<img
						className='carousel-center'
						src='/static/banner/Bnnr-Red-Ciudadana.png'
						alt='Fifth slide'
						//loading='lazy'
					/>
				</a>
			</Carousel.Item>
		</Carousel>
		<br />
		{/* <Container className='d-flex justify-content-center'>
			<Button className='btn-foro' size="lg" active onClick={(e)=> openForo(e)}>
				<Row>
					<Col xs={12}>
						Sigue la trasnmisión en vivo de los foros de Voto en el Extranjero
					</Col>
					<Col xs={12}>
						<img src='/static/facebook_live.png' className='live-img'></img>
					</Col>
				</Row>
			</Button>
		</Container> */}
      </div>
  );

};

export default Carrusel;
