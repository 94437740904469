import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

import './entrevistas.css';

const entrevistas = [
    {
        candidato: 'PAULINA ALEJANDRA DEL MORAL VELA',
        estado: 'EdoMex',
        video: '/assets/entrevistas/EntrevistaAledelMoralSub.mp4',
        imagenPartido: ['/assets/entrevistas/logos_sueltos/pan.png', '/assets/entrevistas/logos_sueltos/pri.png', '/assets/entrevistas/logos_sueltos/prd.png', '/assets/entrevistas/logos_sueltos/NAEM.png'],
    },
    // {
    //     candidato: 'DELFINA GÓMEZ ÁLVAREZ',
    //     estado: 'EdoMex',
    //     video: '/assets/entrevistas/v1.mp4',
    //     imagenPartido: ['/assets/entrevistas/logos_sueltos/PT.png', '/assets/entrevistas/logos_sueltos/pvem.png', '/assets/entrevistas/logos_sueltos/Morena.png'],
    // },
    // {
    //     candidato: 'MANOLO JIMÉMEZ SALINAS',
    //     estado: 'Coahuila',
    //     video: '/assets/entrevistas/EntrevistaAledelMoralSub.mp4',
    //     imagenPartido: ['/assets/entrevistas/logos_sueltos/pan.png', '/assets/entrevistas/logos_sueltos/pri.png', '/assets/entrevistas/logos_sueltos/prd.png'],
    // },
    // {
    //     candidato: 'RICARDO SÓSTENES MEJÍA BERDEJA',
    //     estado: 'Coahuila',
    //     video: '/assets/entrevistas/EntrevistaAledelMoralSub.mp4',
    //     imagenPartido: ['/assets/entrevistas/logos_sueltos/PT.png'],
    // },
    {
        candidato: 'EVARISTO LENIN PÉREZ RIVERA',
        estado: 'Coahuila',
        video: '/assets/entrevistas/EntrevistaLenin.mp4',
        imagenPartido: ['/assets/entrevistas/logos_sueltos/pvem.png', '/assets/entrevistas/logos_sueltos/udc.png'],
    },
    {
        candidato: 'SANTANA ARMANDO GUADIANA TIJERINA',
        estado: 'Coahuila',
        video: '/assets/entrevistas/EntrevistaGuadiana.mp4',
        imagenPartido: ['/assets/entrevistas/logos_sueltos/Morena.png'],
    }
]


const Entrevistas = () => {
  const [entrevistaEM, setEntrevistaEM] = useState(0);
  const [entrevistaC, setEntrevistaC] = useState(1);


  return (
        <React.Fragment>
        <Container className='py-3'>
            <Row>
                <Col xs={12} sm={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <Row>
                        <Col xs={12} className='head-center py-3'>
                            <h3>Coahuila</h3>
                        </Col>
                        <Col xs={12} className='head-center'>
                            <video width="100%" height="auto" controls src={entrevistas[entrevistaC].video} type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                        <Col xs={12} sm={6} className='head-center pt-4'>
                            {entrevistas[entrevistaC].candidato}
                        </Col>
                        <Col xs={12} sm={6} className='head-center pt-2 pb-4'>
                            <Row>
                                {entrevistas[entrevistaC].imagenPartido.map((imgPartido, index) => {
                                    return(
                                        <Col>
                                            <img className='logo-partido' src={imgPartido} key={index}></img>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                            {
                                entrevistas.map((entrevista, index)=>{
                                    if(entrevista.estado === 'Coahuila'){
                                        return(
                                            <Col key={index}  xs={3}>
                                                <Button onClick={()=>setEntrevistaC(index)} variant='light'> 
                                                    <video src={entrevista.video+"#t=15"} alt="" className="thumb-img"/>
                                                    <p className='vid-desc'>{entrevista.candidato}</p>
                                                </Button>
                                            </Col>
                                        )  
                                    }
                                })
                            }
                            </Row>
                        </Col>
                    </Row> 
                </div>
                </Col>
                <Col xs={12} sm={6}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <Row>
                        <Col xs={12} className='head-center py-3'>
                            <h3>Estado de México</h3>
                        </Col>
                        <Col xs={12} className='head-center'>
                            <video width="100%" height="auto" controls src={entrevistas[entrevistaEM].video} type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                        <Col xs={12} sm={6} className='head-center pt-4'>
                            {entrevistas[entrevistaEM].candidato}
                        </Col>
                        <Col xs={12} sm={6} className='head-center pt-2 pb-4'>
                            <Row>
                                {entrevistas[entrevistaEM].imagenPartido.map((imgPartido, index) => {
                                    return(
                                        <Col>
                                            <img className='logo-partido' src={imgPartido} key={index}></img>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                        {/* <Col xs={12}>
                            <Row>
                            {
                                entrevistas.map((entrevista, index)=>{
                                    if(entrevista.estado === 'EdoMex'){
                                        return(
                                            <Col key={index}  xs={3}>
                                                <Button onClick={()=>setEntrevistaEM(index)} variant='light'> 
                                                    <video src={entrevista.video+"#t=15"} alt="" className="thumb-img"/>
                                                    <p className='vid-desc'>{entrevista.candidato}</p>
                                                </Button>
                                            </Col>
                                        )  
                                    }
                                })
                            }
                            </Row>
                        </Col> */}
                        



                        {/* {
                            entrevistas.map((entrevista, index) => {
                                // console.log(index, entrevista.candidato);
                                if(entrevista.estado === 'EdoMex'){
                                    return(
                                        <React.Fragment key={index}>
                                        <Col xs={12} className='head-center'>
                                            <video width="100%" height="auto" controls src={entrevista.video} type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                        </Col>
                                        <Col xs={12} sm={6} className='head-center pt-4'>
                                            {entrevista.candidato}
                                        </Col>
                                        <Col xs={12} sm={6} className='head-center pt-2 pb-4'>
                                            <Row>
                                                {entrevista.imagenPartido.map((imgPartido, index) => {
                                                    return(
                                                        <Col>
                                                            <img className='logo-partido' src={imgPartido} key={index}></img>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Col>
                                        </React.Fragment>
                                    )
                                }
                            })
                        } */}
                    </Row>
                </div>
                </Col>
            </Row>
        </Container>
        </React.Fragment>
  )
}

export default Entrevistas
