import React from 'react'
import { Timeline } from 'react-twitter-widgets'
import { Row, Col } from 'react-bootstrap'
import './redes.css';
//import InstagramEmbed from 'react-instagram-embed'
import { InstagramEmbed } from 'react-social-media-embed';


const index = () => {
  return (    
    <div>
        <hr/>
        <div className='redes-head'>
            <h3>¡SÍGUENOS EN NUESTRAS REDES SOCIALES!</h3>
        </div>
        <Row>
            <Col md={4} sm={12}>
                
                    <div id="fb-root"></div>
                    <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v16.0" nonce="qib06yyg"></script>
                    {/* <div className='videowrapper'>     */}
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Funam.votoinformado&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" style={{display:'block', margin:'0 auto'}} width={500} height={510}  scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    {/* </div> */}
            </Col>
            <Col md={4} sm={12}>
                <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: '_VotoInformado'
                }}
                options={{
                    height: '510%',
                    //width: '30%'
                }}
                />
            </Col>
            <Col md={4} sm={12} className='redes-container'>
                <div style={{ display: 'flex', justifyContent: 'center', maxHeight:'500' }}>
                <InstagramEmbed
                    url="https://www.instagram.com/reel/CsZ2h6ZtaBb/?utm_source=ig_embed&amp;utm_campaign=loading" 
                    maxwidth={328} 
                    height={510} />
                </div>
            {/* <InstagramEmbed
                url='https://instagr.am/p/Zw9o4/'
                clientAccessToken='123|456'
                maxWidth={320}
                hideCaption={false}
                containerTagName='div'
                protocol=''
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
                /> */}
            </Col>
        </Row>
    </div>
  )
}

export default index
