 import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Main from './components/main/index';
import Inicio from './components/views/menu_principal/index';
import Cuestionarios from './components/views/cuestionarios/index';


import NewCuestionario from './components/views/cuestionarios/users/new';
import AdminNewCuestionario from './components/views/cuestionarios/admin/new';

import AdminNewCuestionarioForSingleCandidate from './components/views/cuestionarios/candidates/new';
import Candidatos from './components/views/cuestionarios/candidates/index';


import  { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

import {fetchInitialData} from './actions/dataActions'
import store from './store';
import DivierteteAprende from './components/views/diviertete_aprende';




function App() {

  const dispatch = useDispatch()


  useEffect(() => {
    // declare the async data fetching function
    
    const fetchData = async () => {
      await dispatch(fetchInitialData())


    }


    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

  }, [""])
  

  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path='/' element={ <Main /> }>
                  <Route index element={ <Inicio /> } />
                  <Route path="cuestionarios" element={<Cuestionarios />} />
                  <Route path="responde-el-cuestionario" element={<NewCuestionario />} />
                  <Route path="diviertete-aprende" element={<DivierteteAprende />} />
              </Route>

              <Route path='/admin/' element={ <Main /> }>
                  <Route index element={ <Inicio /> } />
                  <Route path="cuestionarios" element={<Cuestionarios />} />
                  <Route path="guardar-cuestionario" element={<AdminNewCuestionario />} />
                  
              </Route>

              {/*<Route path='/candidatos/' element={ <Main /> }>

                  <Route path="" element={<Candidatos />} />
                  <Route path=":candidateSlug/cuestionario" element={<AdminNewCuestionarioForSingleCandidate />} />
              </Route> */}

             

              <Route path='*' element={ <Navigate replace to="/"/> }/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;