import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import PersonaEquipo from './persona_equipo';

const Equipo = () => {
    const comite = [
        {
          name: 'ADRIANA ESCAMILLA',
          position: 'COORDINADORA GENERAL',
          photo: 'static/equipo/1AdrianaEscamilla.jpg',
          description: 'Politóloga por la Facultad de Ciencias Políticas y Sociales de la UNAM, especialista en Opinión Pública por la misma universidad, actualmente estoy estudiando un Máster en Comunicación Institucional y Política en la Universidad de Sevilla. A nivel profesional, me encanta realizar proyectos con impacto social, sobre todo, fomentar la participación ciudadana como motor de cambio y solución a los problemas que enfrentamos en México y el mundo. A nivel personal, me apasiona viajar, conocer nuevos lugares y culturas, aprender de todas mis experiencias e ir sonriéndole a la vida.',
        },
        {
          name: 'MÓNICA ZAMORA',
          position: 'COORDINADORA OPERATIVA',
          photo: 'static/equipo/2MonicaZamora.jpg',
          //description: 'Estudié Ciencias Ambientales en la Unidad Morelia de la UNAM y cursé un semestre en la Universidad de Granada, España. Estoy interesada en temas de conflictos socioambientales, desde los enfoques de la ecología política y la historia ambiental, así como en la gobernanza de recursos naturales, el ordenamiento territorial comunitario y el monitoreo participativo. Me encanta la colaboración interdisciplinaria, así como promover la vinculación entre academia, gobierno y sociedad y desde 2018 he tenido la oportunidad de participar en esta iniciativa. Dentro de mis aficiones está la divulgación de la ciencia y la documentación fotográfica y del paisaje sonoro.',
          description: 'Estudié Ciencias Ambientales en la ENES Morelia y cursé un semestre en la Universidad de Granada, España, con interés en temas de gobernanza de recursos naturales, ordenamiento comunitario y conflictos socioambientales desde el enfoque de la ecología política. Me encanta la colaboración interdisciplinaria, así como promover la vinculación entre academia, gobierno y sociedad y desde 2018 he participado en esta iniciativa. Dentro de mis aficiones están la divulgación de la ciencia y la fotografía.',
        },
        {
          name: 'ALEJANDRA HERNÁNDEZ TECORRALCO',
          position: 'RELACIONES INSTITUCIONALES',
          photo: 'static/equipo/3AlejandraTecorralco.jpg',
          description: 'Poblana de corazón. Comprometida con tener un mejor país. Estoy interesada en la migración, cuidado del medio ambiente y el fortalecimiento de la democracia en México.',
        },
        {
          name: 'ANA LAURA FLORES TORRES ',
          position: 'ANALISTA Y CREADORA DE CONTENIDO',
          photo: 'static/equipo/4AnaFlores.jpg',
          description: 'Mujer (ella), joven y feminista. Estudié Ciencias Políticas y Administración Pública en la FCPyS e hice un diplomado en Género, Política y Políticas Públicas en la UNAM. Fanática de la incidencia de las juventudes; investigación y creación de contenido sobre democracia y participación ciudadana, participación política de las mujeres y derechos sexuales y reproductivos. Soy leo, adoro viajar, tengo muchas plantas, dos perritos, un cuyo, pájaros y muchas lombrices.',
        },
        {
            name: 'ABIGAÍL RODRÍGUEZ GUDIÑO',
            position: 'ANALISTA Y CREADORA DE CONTENIDO',
            photo: 'static/equipo/5AbigailRodriguez.jpg',
            description: 'Mujer (ella). Soy licenciada en Ciencia Política por la UNAM. Dentro de mis intereses se encuentra el estudio de la crisis climática, la ecología política, la defensa de los cuerpos y territorios así como los derechos humanos. También puedo pasar mis días aprendiendo del mundo vegetal, los animales no humanos y nadando.',
          },
          {
            name: 'IVANA LEDESMA COPCA',
            position: 'ANALISTA Y CREADORA DE CONTENIDO',
            photo: 'static/equipo/6IvanaLedesma.jpg',
            description: 'Mujer (ella). Estudié Ciencia Política y Administración Pública en la FCPyS. Me gustan mucho los temas relacionados con cultura, arte, políticas culturales, museografía y las muchas manifestaciones socioculturales que hay en los espacios públicos. Disfruto mucho de caminar por la ciudad y encontrar cafeterías nuevas.',
          },
          {
            name: 'KARLA VEGA URIBE',
            position: 'DISEÑADORA Y COMUNICADORA DIGITAL',
            photo: 'static/equipo/7KarlaVega.jpg',
            description: 'Licenciada en Diseño y Comunicación Visual de la FAD, UNAM. Comprometida con la educación a través de la imagen. Apasionada del arte, el dibujo, lo hecho a mano y la cultura popular en los medios.',
          },
          {
            name: 'MAETTZY ALMARAZ AYALA',
            position: 'DISEÑADORA Y FOTÓGRAFA',
            photo: 'static/equipo/8MaettzyAlmaraz.jpg',
            description: 'Mujer (ella) Pasante de la licenciatura de Diseño y Comunicación Visual por la Facultad de Artes y Diseño. Con inclinación en los estudios de la imagen fotográfica y sus implicaciones sociales. Interesada en temas de bienestar animal. ',
          },
          {
            name: 'CÉSAR VEGA URIBE',
            position: 'EDITOR Y CREADOR DE CONTENIDO DIGITAL',
            photo: 'static/equipo/9CesarVega.jpg',
            description: 'Alumno de Postproducción de la Academia de San Carlos FAD/UNAM. Apasionado del cine y los videojuegos, con gran interés en transmitir de forma visual la importancia de la salud mental y emocional. Aún no he podido terminar de conocerme y sigo en búsqueda de ello.',
          },
          {
            name: 'ARIADNE RODRÍGUEZ REYES',
            position: 'ENLACE INSTITUCIONAL',
            photo: 'static/equipo/10AriadneRodriguez.jpg',
            description: '(She/Her) Internacionalista, tesista y  profesora adjunta de la FCPyS. Los temas que me apasionan son: la seguridad humana, migraciones, ciberseguridad, cambio climático, desarrollos sustentables, justicia ambiental, producción de espacios y geopolítica. También soy bordadora amateur, curadora de playlist, amante de perritos y cuidadora de plantas aficionada.',
          },
          {
            name: 'PAOLA ADRIANA SEGURA CALVO',
            position: 'COORDINADORA ACADÉMICA',
            photo: 'static/equipo/11PaolaSegura.jpg',
            description: 'Mujer (She/her) y feminista. Estudié Administración y Fisioterapia especializada en procesos somáticos. Fanática de la incidencia permacultural; la democracia, el derecho a la vida, la participación en colectividad y la democracia. Interesada en el poder de las plantas, adoro viajar y admirar las manifestaciones socioculturales.',
          },
          {
            name: 'XCHEL MARTÍN MANRIQUE AVENDAÑO',
            position: 'MEDIOS TRADICIONALES',
            photo: 'static/equipo/12XchelManrique.jpg',
            description: 'Soy egresado de Ciencias de la Comunicación del área de Comunicación Política con interés en temas de procesos electorales, comunicación de gobierno, campañas digitales y en estudios de la región del Asia-Pacífico. Actualmente curso la especialidad en Análisis Político. Soy apasionado por los automóviles, el k-pop y de viajar cuando se presenta la oportunidad.',
          },
      ];
  return (
    <div className='full-width'>
        <div className='head-center py-5'>
            <h1>EQUIPO DE TRABAJO</h1>
        </div>
        <Container>
            <div className='head-center pb-4'>
                <h3>INTEGRANTES</h3>
            </div>
            <Row>
            {comite.map((member, index) => {
                return (
                    <Col xs={12} sm={4}>
                      <PersonaEquipo
                        name={member.name}
                        position={member.position}
                        photo={member.photo}
                        description={member.description}
                        index={index}
                      />
                    </Col>
                  );
              })}
            </Row>
        </Container>
    </div>
  )
}

export default Equipo
