import React from 'react'
import { Modal, Button, Container, Row, Col, Spinner } from 'react-bootstrap'

const ForoModal = (props) => {
    const foro = {
        id: 1,
        data:{
            title: '',
			description: '',
            link: '',
        }
    }

  return (
    <Modal show={(foro.data.link !== '' ? props.showForo : false)} onHide={props.closeForo} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
    {/* <Modal show={props.showForo} onHide={props.closeForo} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}> */}
       <Modal.Header className='px-5' closeButton>
          <Modal.Title>
            <div style={{color:'#F7931E'}}>{foro.data.title}</div>
            <div className='d-flex justify-content-center align-items-center'>
                {foro.data.description}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
            foro.data.link !== '' ? 
                <Container className='d-flex justify-content-center align-items-center'>
                    <iframe src={foro.data.link} width="1080" height="720" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0" allowfulldcreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </Container>
            :
                <Container className='pt-5'>
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center py-3'>
                            <h5>La transmisión estará disponible a las 10:00 hrs.</h5>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center py-3'>
                            {/* <Spinner animation="border" variant="secondary" /> */}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                            </svg> */}
                            <img src='/static/banner/Banner_3.png' className='w-75'></img>
                        </Col>
                    </Row>
                </Container>
            }
        </Modal.Body>
        {/* <Modal.Footer>
        <Button variant="secondary" onClick={(e)=>props.closeForo(e)}>
            Close
        </Button>
        </Modal.Footer> */}
    </Modal>
  )
}

export default ForoModal
