export const getApiUrl = () => {
    
    // if(window.location.hostname === 'votoinformado.com.mx' || window.location.hostname === 'www.votoinformado.com.mx'){
    //     return `votoinformado.host`;
    // }
    // else{
    //     return `api.votoinformado.com.mx`;
    // }
    if (window.location.hostname.indexOf('votoinformado') > -1) {
        const url = window.location.hostname.indexOf('www.') > 0 ? window.location.hostname : window.location.hostname.replace('www.','');
        return `api.${url}`;
    } else {
        return `${window.location.hostname}/api`;
    }
}   