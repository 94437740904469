import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import ModalBanner from './ModalBanner/modalBanner';
import './header.css';


const Header = () => {
  const [showTarjeta, setShowTarjeta] = useState(true);

  const handleTarjeta = () => {
    setShowTarjeta(!showTarjeta);
  }
  return (
    <div>
    <Navbar className='navbar-voto' variant="dark" >
        <Container>
            <Navbar.Brand href="#home">
              <a href='/'>
            <img
                alt=""
                src="/static/logos/LogoVI_horizontal_blanco.png"
                width="200"
                height="80"
                className="d-inline-block align-top"
            />
            </a>
            </Navbar.Brand>
        </Container>
    </Navbar>
    <ModalBanner
      show={showTarjeta}
      handleClose={()=>handleTarjeta()}
      title='Voto Informado'
      verticalAlign
    >
      <img src='static/banner_header/BannerNosVemosPronto.png' className='img-ve'></img>
    </ModalBanner>
  </div>
  )
}

export default Header
