import React from 'react'
import { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import './voto_extranjero.css';
import TarjetaModal from './tarjeta_modal';

const VotoExtranjero = ({openForo}) => {
    const foros = [
		{
			title: 'FORO 1',
			description: 'Voto en el extranjero, panorama en Coahuila. La comunidad migrante, una mirada estratégica en el ejercicio del voto en un estado fronterizo.',
			dia: 'Sábado 13 de mayo de 2023',
            hora: '10am (tiempo de la cdmx)',
            sede: 'Dallas, Texas',
            link: '',
			open: false,
		},
		{
			title: 'FORO 2',
			description: 'Voto en el extranjero, panorama en el Estado de México. Retos y oportunidades de la democracia frente a una realidad migrante.',
			dia: 'Sábado 13 de mayo de 2023',
            hora: '10:00 - 12:00 hrs.',
            link: '',
			open: false,
		},
        {
			title: 'FORO 3',
			description: 'La importancia de la participación de las y los mexicanos en el extranjero en los procesos electorales. Desde cualquier rincón, las diferentes modalidades de ejercer tu voto en el extranjero.',
			dia: 'Sábado 20 de mayo de 2023',
            hora: '10:00 - 12:00 hrs.',
            link: '',
			open: false,
		},
        {
			title: 'FORO 4',
			description: 'Iniciativas que fomentan el voto de las y los mexicanos en el extranjero. No lo olvides, tu voto vale desde cualquier latitud.',
			dia: 'Sábado 27 de mayo de 2023',
            hora: '10:00 - 12:00 hrs.',
            link: '',
			open: false,
		},
    ];


  const [showTarjeta, setShowTarjeta] = useState(false);
  const [foro, setForo] = useState(null);

  const handleTarjeta = (index) => {
    const foro = foros[index];
    setForo(foro);
    setShowTarjeta(true);
  }
  return (
    <React.Fragment>
    <Container>
        <Row className='pb-3'>
            <Col xs={12} className='pb-4 pt-3'>
                <h1>VOTO EN EL EXTRANJERO</h1>
            </Col>
           <Col sm={4} xs={12} className='img-center'>
                <img src='static/voto_extranjero/LogoVE_color.png' className='img-ve'></img>
            </Col>
            <Col sm={8} xs={12} className='mb-4 text-align'>
                <p className="text-justification">
                Nuestro objetivo principal es promover la participación informada de la ciudadanía,
                principalmente de las y los jóvenes electores que radican en el extranjero, a través
                de herramientas y acciones dirigidas a divulgar información y conocimiento sobre las
                candidaturas a cargos de elección popular, este año para las elecciones en Coahuila
                y Estado de México.
                <br/> <br/>
                Por ello, haremos cuatro Foros Informativos sobre los Procesos Electorales Locales
                2022-2023 para Mexicanas y Mexicanos Residentes en el Extranjero.
                </p>
            </Col>
        </Row>
    </Container>
    <div className='full-width'>
        <Row className='row-mision-vision'>
            <Col xl={3} md={6} sm={6} xs={12}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <a onClick={() => handleTarjeta(0)}>
                        <img src='static/voto_extranjero/ModalForo_1.png' className='card-foro'></img>
                    </a>
                </div>
            </Col>
            <Col xl={3} md={6}  sm={6} xs={12}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <a onClick={() => handleTarjeta(1)}>
                        <img src='static/voto_extranjero/ModalForo_2.png' className='card-foro'></img>
                    </a>
                </div>
            </Col>
            <Col xl={3} md={6}  sm={6} xs={12}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <a onClick={() => handleTarjeta(2)}>
                        <img src='static/voto_extranjero/ModalForo_3.png' className='card-foro'></img>
                    </a>
                </div>
            </Col>
            <Col xl={3} md={6}  sm={6} xs={12}>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <a onClick={() => handleTarjeta(3)}>
                        <img src='static/voto_extranjero/ModalForo_4.png' className='card-foro'></img>
                    </a>
                </div>
            </Col>
        </Row>
        <Container className='img-center pb-5'>
        
          {/* <Button className='btn-foros'  size="lg" active onClick={(e)=> openForo(e)}>
            Sigue la trasnmisión de los foros
          </Button> */}
        </Container>
    </div>
    <Container className='py-5'>
        <Row>
            <Col xs={12} className='py-2'>
                <div className='head-center'>
                <h5>SOY MEXICANO/MEXICANA Y VIVO EN EL EXTRANJERO, ¿DESDE AQUÍ PUEDO VOTAR?</h5>
                </div>
            </Col>
            <Col xs={12}>
                <p className='head-center'>Tu voto cuenta para lograr el México que quieres.</p>
                <br/>    
                <p className='px-5'>
                Las y los mexicanos que viven en el extranjero, mayores de 18 años de edad, tienen
                el derecho de hacer valer su poder de decisión en México donde sea que se encuentren.
                <br/><br/>
                Ejerce tu derecho al voto desde el extranjero y participa en las decisiones que se
                toman en nuestro país.
                </p>
            </Col>
        </Row>
    </Container>
    <div className='full-width'>
        <div className='head-center pt-5'>
            <h5>¿QUÉ NECESITO PARA VOTAR EN EL EXTRANJERO?</h5>
        </div>
        <Row className='row-mision-vision'>
            <Col xs={12} className='img-center pb-5'>
                <img src='static/voto_extranjero/InfografiaQueNecesito_1.png' className='img-que-necesito'/>
            </Col>
            <Col xs={12} className='img-center pb-4'>
                <p className='px-5'>
                Recuerda que si eres del Estado de México o Coahuila puedes votar desde el
                extranjero para elegir a la próxima o próximo gobernador.<br/><br/>
                Necesitas:
                <ul>
                    <li>Ser originaria u originario de esa entidad</li>
                    <li>Tener a la mano tu credencial para votar vigente (la fecha límite para tramitarla fue el 12 de febrero)</li>
                    <li>Registrarte antes del 10 de marzo en <a href='https://votoextranjero.mx/web/vmre/inicio' target='_blank'>https://votoextranjero.mx/web/vmre/inicio</a></li>
                </ul>
                </p>
            </Col>
        </Row>
    </div>
    <Container className='py-5'>
        <Row>
            <Col xs={12} className='py-2'>
                <div className='head-center pb-2'>
                    <h5>MODALIDADES PARA EJERCER TU VOTO</h5>
                </div>
            </Col>
            <Col xs={12} md={4}  lg={4} xl={4}  className='img-center mb-3'>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='img-center'>
                        <p>VOTO PRESENCIAL</p>
                    </div>
                    <iframe width="300" height="500" //width="560" height="315"
                        src="https://www.youtube.com/embed/18a2bWxIu6Y"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                </div>
            </Col>
            <Col xs={12} md={4} lg={4} xl={4}  className='img-center mb-3'>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='img-center'>
                        <p>VOTO POSTAL</p>
                    </div>
                    <iframe width="300" height="500"
                        src="https://www.youtube.com/embed/goAqH_76tKk"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                </div>
            </Col>
            <Col xs={12} md={4} lg={4} xl={4} className='img-center mb-3'>
                <div className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className='img-center'>
                        <p>VOTO ELECTRÓNICO</p>
                    </div>
                    <iframe width="300" height="500" //width="560" height="315"
                        src="https://www.youtube.com/embed/-EV7CrV9Izw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                </div>
            </Col>
        </Row>
    </Container>
    <TarjetaModal
        show={showTarjeta}
        handleClose={()=>setShowTarjeta(false)}
        foro={foro}
        // link={linkModal}
    >
    </TarjetaModal>
    </React.Fragment>
  )
}

export default VotoExtranjero
