
import axios from "axios";
// import { useDispatch } from 'react-redux'
import { getApiUrl } from "./helper";
import { saveCandidatos,setUserAswers } from '../reducers/appReducer'

//import cuestionario from '../cuestionarios_json/cuestionario_de_usuario.json';


export const fetchInitialData = () => async (dispatch) => {
  //const dispatch = useDispatch()
  try {
    console.log("fetchInitialData")

    

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${window.location.protocol}//${getApiUrl()}/data`,
      {params: {}},
      config
    );
   

    console.log(data)
    data.forEach((candidate) => {
      //if(candidate.with_answers){
       // console.log(candidate)
        candidate.answers = JSON.parse(candidate.answers)
        candidate.answers.forEach((answer) => {
          answer.visible = false

          var subquestions = answer.subquestions
          if(subquestions){
            subquestions .forEach((subanswer) => {
              subanswer.visible = false
            });
          }
     
        }); 
      //}
      
    });


    /*const userAnswers=[]
    cuestionario.forEach(question => {
        if(question.name === "question"){



          userAnswers.push( {id:question.id, value:-1} )
        }
    });*/  
        

    


    //dispatch(setUserAswers(userAnswers))
    dispatch(saveCandidatos(data))

    //localStorage.setItem("personaInfo", JSON.stringify(data));
  } catch (error) {
    console.log("Error al guardar fetchInitialData")
    console.log(error)
   
  }
};