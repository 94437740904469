import React from 'react'
import Count from './counter.jsx'
import './counter.css'

const index = () => {
  return (
    <div className='counter-frame'>
        <Count />
    </div>
  )
}

export default index
