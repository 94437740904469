


import { createSlice } from '@reduxjs/toolkit'

export const appReducer = createSlice({
  name: 'appData',
  initialState: {


    //userCuestionario: {},
    candidatos: [],
    currentAnswer:{id:-1,value:-1},
    userAswers:[],
    answersCode:undefined,
    userInfo: {email:"",age:0,genero:-1,estado:-1},
    currentQuestionToShow:undefined,


    candidateAnswer: {
      candidateId: "-1",
      estado: "-1",
      answers:[]
    }


  },
  reducers: {
    

    setCandidateAnswerCandidateID: (state, action)  => {
      state.candidateAnswer.candidateId = action.payload
    },

    setCandidateAnswerCandidateEstado: (state, action)  => {
      state.candidateAnswer.estado = action.payload
    },

    setCandidateAnswers: (state, action)  => {
      state.candidateAnswer.answers = action.payload
    },

    setCandidateAswer: (state, action) => {

      const value= action.payload.value
      const questionID= action.payload.id



      var answersClone = Object.assign([], state.candidateAnswer.answers); 

      const answerFound =  answersClone.find(answer => answer.id === questionID );


      //if(answerFound){
        answerFound.value = value

      /*}else{
        answersClone.push({id:questionID,value : value})
      }*/

        state.candidateAnswer.answers = answersClone
    },

    setCandidateAswerMultiple: (state, action) => {
      const value= action.payload.value
      const questionID= action.payload.id
      const subQuestionID= action.payload.subQuestionId



      var answersClone = Object.assign([], state.candidateAnswer.answers ); 

      const answerFound =  answersClone.find(answer => answer.id === questionID );

      const subAnswerFound =  answerFound.subquestions.find(answer => answer.id === subQuestionID );


      subAnswerFound.value = value

      state.candidateAnswer.answers  = answersClone

    },



    saveCandidatos: (state, action) => {
      state.candidatos = action.payload
    },


    saveCuestionariosCandidato: (state, action) => {
    
      const found = state.candidatos.find(candidato => candidato.id == action.payload.candidateId);
      found.answers = action.payload.answers

      


    },

    updateAnswerVisibility: (state, action) => {
      const answerId = action.payload.answerId
      state.candidatos.map((candidate, index) => 
        state.candidatos[index].answers.map((answer, index2) => 

            state.candidatos[index].answers[index2].id === answerId ? state.candidatos[index].answers[index2].visible = true : state.candidatos[index].answers[index2].visible = false
          
        )
      )
    },
    updateAnswerVisibilityMultiple: (state, action) => {

      console.log(action.payload)
      const answerId = action.payload.answerId
      const subAnswerId = action.payload.subAnswerId
      const visibility = action.payload.visibility

     

      state.candidatos.map((candidate, index) => 
        candidate.answers.map((answer, index2) => 
          answer.subquestions.map((answer2, index3) => 

            answer2.id === subAnswerId ? answer2.visible = true : answer2.visible = false
            //answer2.visible = visibility
          )
        )
      )


    },

    setCurrentAswer: (state, action) => {
      state.currentAnswer = action.payload
    },

    setUserAswers: (state, action) => {

        state.userAswers = action.payload
        //state.answersCode = action.payload.code

    },

    setUserAswersCode: (state, action) => {

        state.answersCode = action.payload

    },

    setUserAswer: (state, action) => {

      const value= action.payload.value
      const questionID= action.payload.id

      var answersClone = Object.assign([], state.userAswers); 

      const answerFound =  answersClone.find(answer => answer.id === questionID );


      //if(answerFound){
        answerFound.value = value

      /*}else{
        answersClone.push({id:questionID,value : value})
      }*/

        state.userAswers = answersClone
    },


    setUserAswerMultiple: (state, action) => {
      const value= action.payload.value
      const questionID= action.payload.id
      const subQuestionID= action.payload.subQuestionId



      var answersClone = Object.assign([], state.userAswers); 

      const answerFound =  answersClone.find(answer => answer.id === questionID );

      const subAnswerFound =  answerFound.subquestions.find(answer => answer.id === subQuestionID );


      subAnswerFound.value = value

      state.userAswers = answersClone

    },




    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },

    setCurrentQuestionToShow: (state, action) => {
      state.currentQuestionToShow = action.payload
    },


   
    




    
  


  }
})

// Action creators are generated for each case reducer function
export const {setCandidateAnswerCandidateID,setCandidateAnswerCandidateEstado,setCandidateAnswers, setCandidateAswer, setCandidateAswerMultiple,  saveCandidatos,saveCuestionariosCandidato,updateAnswerVisibility,setCurrentAswer,setUserAswers,setUserAswer,setUserInfo,setCurrentQuestionToShow,setUserAswersCode,setUserAswerMultiple,updateAnswerVisibilityMultiple} = appReducer.actions

export default appReducer.reducer