import React from 'react'
import {Container} from 'react-bootstrap';
import Footer from '../structure/footer';
import Header from '../structure/header';
import { Outlet } from 'react-router-dom';

const index = () => {
  return (
    <div>
        <Header/>
        <Container>
            <Outlet />
        </Container>
        <Footer/>
    </div>
  )
}

export default index
