import React from 'react'
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Inicio from '../inicio';
import Elecciones from '../elecciones';
import QuienesSomos from '../quienes_somos';
import Contacto from '../contacto';
import VotoExtranjero from '../voto_extranjero';
import Entrevistas from '../entrevistas';

const MenuPrincipal = () => {
    const [key, setKey] = useState('inicio');
    const [keyEstado, setKeyEstado] = useState('coahuilaTab');
    const handleSelect = (eventKey) => setKeyEstado(eventKey);

    // const [loadedForo, setLoadedForo] = useState(false);
    const [showForo, setShowForo] = useState(true);
    const closeForo = () =>{
      setShowForo(false);
    }
    const openForo = (e) => {
      e.preventDefault();
      setKey('inicio');
      setShowForo(true);
    }

    return (
      <Tabs
        // defaultActiveKey="inicio"
        id="justify-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        justify
      >
        <Tab eventKey="inicio" title="Inicio">
          <Inicio setKey={setKey} handleSelect={handleSelect} showForo={showForo} closeForo={closeForo} openForo={openForo}/>
        </Tab>
        <Tab eventKey="elecciones" title="Elecciones">
          <Elecciones handleSelect={handleSelect} keyEstado={keyEstado}/>
        </Tab>
        <Tab eventKey="entrevistas" title="Entrevistas">
          <Entrevistas/>
        </Tab>
        <Tab eventKey="voto_extranjero" title="Voto en el extranjero">
          <VotoExtranjero openForo={openForo}/>
        </Tab>
        <Tab eventKey="contacto" title="Contacto">
          <Contacto />
        </Tab>
        <Tab eventKey="quienes" title="¿Quiénes somos?">
          <QuienesSomos />
        </Tab>
      </Tabs>
    );
}

export default MenuPrincipal
