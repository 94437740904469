import React from "react";
import { Row, Col } from "react-bootstrap";

const PersonaEquipo = ({name, position, photo, description}) => {

    return (
        <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <Row>
                <Col xs={12} className='col-center'>
                    <img src={photo} className='img-perfil-comite' />
                </Col>  
                <Col xs={12} className='col-center'>
                    <h5>{name}</h5>
                </Col>
                <Col xs={12} className='col-center'>
                    {position}
                </Col>
                <Col sm={12} xs={12} className='px-4 py-3'>
                    <p className="text-justification">{description}</p>
                </Col>
            </Row>
        </div>
    );
};

export default PersonaEquipo;