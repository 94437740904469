import React from 'react'
import  { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux'

import {setCandidateAnswerCandidateIDAction, setCandidateAnswerCandidateEstadoAction,setCandidateAnswerAction,setCandidateAswerAction,setCandidateAswerMultipleAction, saveCandidateCuestionarioAction,   saveUserCuestionarioAction,updateAnswerVisibilityAction,setCurrentAswerAction,setUserAswerAction,setUserInfoAction,setCurrentQuestionToShowAction,fetchCuestionarioAction,setUserAswersCodeAction,setUserAswersAction,setUserAswerMultipleAction,updateAnswerVisibilityMultipleAction} from '../../../../actions/cuestionariosActions'



import { Badge,Alert,Form,Row, Col,Button,Card,ListGroup ,Accordion} from 'react-bootstrap'

import Swal from 'sweetalert2'






import cuestionarioJSONedoMex from '../../../../cuestionarios_json/cuestionario_de_usuario_edo_mex.json';
import cuestionarioJSONcoahuila from '../../../../cuestionarios_json/cuestionario_de_usuario_coahuila.json';

import cuestionarioJSON from '../../../../cuestionarios_json/cuestionario_de_usuario.json';





//import styles from './new.css';





function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}





function Candidatos(props) {

	  	
			const dispatch = useDispatch()

	  	const candidatos = useSelector(state => state.appData.candidatos)
	  	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)


	  	const handleCandidatoSelected = (event) => {
	    	const found = candidatos.find(candidato => candidato.id == event.target.value);
	    	
	    	dispatch(setCandidateAnswerCandidateIDAction(found.id))  

			}

			const handleEstadoSelected = (event) => {
	    	dispatch(setCandidateAnswerCandidateEstadoAction( parseInt(event.target.value)) )

	   		
	   		if(event.target.value !== -1 || event.target.value !== "" || event.target.value !== undefined ){

	   			loadCuestionario(event.target.value)
	   		}

			}





			const loadCuestionario = (estado) => {


				 //if(candidateAnswer.estado !== -1){

					
    	
		      var cuestionario =[]
		        if(estado==0){
		          cuestionario = cuestionarioJSONedoMex
		        }else{
		          cuestionario = cuestionarioJSONcoahuila
		        }
		    	
		    	const userAnswers=[]
		      cuestionario.forEach(question => {
		            if(question.name === "question"){

		              var answer = {id:question.id, value:-1, subquestions:[]}

		              if(question.attributes.type=="multiple"){
		                question.attributes.subquestions.forEach(subquestion => { 
		                  answer.subquestions.push({id:subquestion.id, value:-1})
		                });
		              }
		              if(question.attributes.type=="priority"){
		                question.attributes.subquestions.forEach(subquestion => { 
		                  answer.subquestions.push({id:subquestion.id, value:-1})
		                });
		              }

		              if(question.attributes.type=="multitext"){
		                question.attributes.subquestions.forEach(subquestion => { 
		                  answer.subquestions.push({id:subquestion.id, value:-1})
		                });
		              }

		              userAnswers.push( answer )
		              
		            }
		      });
		      
		      dispatch(setCandidateAnswerAction(userAnswers))

    /*}else{
    	Swal.fire({
          title: 'Error!',
          text: 'Para continuar es necesario seleccionar un estado de la lista',
          icon: 'error',
          confirmButtonText: 'OK'
        })  
    }*/
	    	
	}


	const findCurrentEstadoAnswer = () => {  

    return candidateAnswer.estado+""
  }

  const findCurrentCandidateAnswer = () => {  

    return candidateAnswer.candidateId+""	
  }




	  	//if (props.show) {  
	      return (
	      	<Row>
	  				<Col className='col-12'>	
		  				<Form.Select defaultValue={findCurrentCandidateAnswer()} size="lg" onChange={handleCandidatoSelected}>
		  					<option   value='-1'>Selecciona un candidato</option>
		  					{candidatos.map((candidato,index)=>{
							    
									return <option key={candidato.id} value={candidato.id}>{candidato.name}</option>
							    

							})}
			      </Form.Select>  				
			      </Col>
			      <Col className='col-12'>
			      	 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>¿De qué estado eres?</Form.Label>
            
                      <Form.Select defaultValue={findCurrentEstadoAnswer()} size="lg" onChange={handleEstadoSelected}>
                          <option    value='-1'>Selecciona una opción</option>
                          <option   value={0}>Estado de México</option>
                          <option   value={1}>Coahuila</option>
                      </Form.Select>
                    </Form.Group>

			      </Col>
  			</Row>
	        
	      );

	   	// }  
	  }



	const Cuestionario = (props) => {

	const dispatch = useDispatch()
	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
	const [cuestionario, setCuestionario] = useState( [] );

  useEffect(() => {

   
  	if(candidateAnswer.estado !== -1){
  		if(candidateAnswer.estado==0){
        setCuestionario(cuestionarioJSONedoMex)
		  }else{
	      setCuestionario(cuestionarioJSONcoahuila)
		  }	
  	}
   	
    
    
  }, [candidateAnswer])











  return (
    

    //<pre>{JSON.stringify(cuestionario , null, 2)}</pre>
    <Row>
      <Col class='col-12'>

      	
       	{cuestionario.map((question,index)=>{
          return(
          	<Block question={question} />
          )	
       		

        })}
      </Col>
    </Row>
  )

}


function Block(props) {

  const currentQuestionToShow  = props.question


  //<Question  goToNextQuestion={props.goToNextQuestion} goToPrevQuestion={props.goToPrevQuestion} userOptionSelected={props.userOptionSelected}/>

  
    /**/


 switch (currentQuestionToShow.name) {
 	  case "question":
 	    return(

 	      <Question question={currentQuestionToShow}/>
        
 	    )
 	}
 	         
 
    
}



function Question(props) {

  const currentQuestionToShow  = props.question

    return (
      <Row className='subquestions shadow form-contro mt-3 mb-3 pt-2 pb-2'>

          <Col xs={12}>
            {/*<Alert key='success' variant='primary'>*/}
              <Row>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12}> 
                  <h5>{currentQuestionToShow.attributes.block}</h5>
                  <h4>{currentQuestionToShow.attributes.label}</h4>
                </Col>
              </Row>                     
            {/*</Alert>*/}
            
              
          </Col>
          <Col xs={12}>
            <Row> 
              <Col xs={12} sm={12} md={12} lg={12}>
                <Input key={uuidv4()} question={currentQuestionToShow}/>
              </Col>
              
            </Row>
            
              
           
          </Col>
 
          
      </Row>
    );
}


function Input(props) {
const currentQuestionToShow  = props.question

  switch (currentQuestionToShow.attributes.type) {
    
    case "multiple":
      return(
        <InputMultiple question={currentQuestionToShow}/>
        
      )
    case "dropdown":
      return(
        <InputDropdown  question={currentQuestionToShow}/>
      )
    case "priority":
      return(
        <InputPriority question={currentQuestionToShow} />
      )
  }
}



function InputDropdown(props) {

  const dispatch = useDispatch()


  const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
  const currentQuestionToShow  = props.question


  //const [cuestionario, setCuestionario] = useState(cuestionarioJSON);


  

  useEffect(() => {
    /*console.log("IN useEffect InputDropdown")  

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    if (answerFound.value != -1 || answersCode!= undefined){
      dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true}))  
    }*/
    
  }, [candidateAnswer]) 



  const onInputChangeDropdown = (event) => {  
   	dispatch(setCandidateAswerAction({id:currentQuestionToShow.id,   value : event.target.value}))


  }


  const findCurrentAnswer = () => {  

    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );
    if (answerFound)
    	return answerFound.value
    else
    	return -1
  }


  function ShowDropDown(){
    return(
        <Form.Select  defaultValue={findCurrentAnswer()} size="lg" id={currentQuestionToShow.id} onChange={onInputChangeDropdown}>
          <option  disabled value='-1'>Selecciona una opción</option>
          {currentQuestionToShow.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
      )
  }

  



  //<pre>{JSON.stringify(currentQuestionToShow , null, 2)}</pre>
      return(

      		<Row className=''>
      			<Col>
		         	<ShowDropDown />
		         </Col>
		      </Row>


          
      )
  
    
}




function InputMultiple(props) {

  const dispatch = useDispatch()

  const currentQuestionToShow  = props.question
 const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)


  

  function ShowDropDownForSubQuestion(props){

  
  const [subquestion, setsubquestion] = useState(props.subquestion);
  


  

  const findCurrentAnswer = (subQuestionId) => {  
    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );


    

    if(answerFound){
    	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
    	if(subAnswerFound)
    		return subAnswerFound.value
    	else
    		return -1	
    }else
    	return -1
  }

  const onInputChangeDropdown = (subQuestion) =>(event) => {  



    dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id }))
    /*.then((value) => {
      

    }).catch((error) =>{
      
      console.log(error)
    })*/
  }


    return(
        <Form.Select  defaultValue={findCurrentAnswer(subquestion.id)} size="lg" id={subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
          <option  key={uuidv4()} disabled value='-1'>Selecciona una opción</option>
          {subquestion.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
    )
  }


  

  return(

      <Row>
        {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
          <Col xs={12} key={uuidv4()} className="mt-2">
              <Row className='mb-4'>
                <Col xl={12}>
                   {subquestion.attributes.label}
                </Col> 
                <Col xl={12}>
                  <ShowDropDownForSubQuestion subquestion={subquestion}/> 
                </Col>
              </Row>
          </Col>
        ))} 

      </Row>
  ) 
}





function InputPriority(props) {

  const dispatch = useDispatch()

  const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)
  const currentQuestionToShow  = props.question
 const [choices, setchoices]= useState(currentQuestionToShow.attributes.choices);
  
  

  

 



  const restartSelects = (optionSelected) => {  
    
    const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

    answerFound.subquestions.forEach(choice => {
      //dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id, value:-1, subQuestionId:choice.id }))
    });


        
  }


  

  function ShowDropDownForPrioritySelect(props){

    const [subquestion, setsubquestion] = useState(props.subquestion);
    const [choices, setchoices]= useState([]);



    useEffect(() => {
      setAvailableOptions()
     	
    
    }, [])  

  

    const findCurrentAnswer = (subQuestionId) => {  
     
      const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

      if(answerFound){
	      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
	      return subAnswerFound  
	    }else
	    	return -1
    }


    const onInputChangeDropdown = (subQuestion) => (event) => {  
  

    dispatch(setCandidateAswerMultipleAction({id:currentQuestionToShow.id, value:event.target.value, subQuestionId:subQuestion.id }))

    /*.then((value) => {
        
     

    }).catch((error) =>{
      
      console.log(error)
    })*/
    }

    const getOptionSelectedLabel = (subQuestionId) => {  
      const answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

      if(answerFound){
      	const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
        const choice = currentQuestionToShow.attributes.choices.find(choice => choice.value === subAnswerFound.value)
        return choice.label
      }else{
      	return ""
      }

    }


    const setAvailableOptions = () => {  
      var choices = []
      var answerFound = null
      var subAnswerFound =null

      currentQuestionToShow.attributes.choices.forEach(choice => {

       	answerFound = candidateAnswer.answers.find(answer => answer.id === currentQuestionToShow.id );

       	if(answerFound){
       		subAnswerFound =answerFound.subquestions.find(answer => answer.value === choice.value )
        

	        if(subAnswerFound == undefined){
	          choices.push({label: choice.label,value:choice.value})  
	        }	
       	}
        



      })

      setchoices(choices)

    }

   
    return(
      <div>
        
        {  findCurrentAnswer(subquestion.id).value ===-1 ? (
            <Form.Select  defaultValue={findCurrentAnswer(subquestion.id).value} size="lg" id={"forms-"+subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
            <option  key={uuidv4()}  value='-1'>Selecciona una opción</option>
            {choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
              
            
            ))}
          </Form.Select>
          ) : (
            <div className="form-control"> {getOptionSelectedLabel(subquestion.id) } </div>
          )

        }
      </div>
    )
  }




  return(
    
            <Row >
              {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
                <Col xl={12}  key={uuidv4()} className="mt-2">
                  <Form.Group className="mb-2" controlId={"formg-"+subquestion.id}>
                    <Form.Label>{subquestion.label}</Form.Label>
                      <ShowDropDownForPrioritySelect subquestion={subquestion}/> 
                  </Form.Group>
                </Col>
              ))}
              <Col xl={12}  key={uuidv4()} className="mt-2">
                <Button variant="primary" type="button" onClick={restartSelects}>Reiniciar</Button>
              </Col>
            </Row>
  ) 
  
    
}




const NewCuestionario = () => {

	const dispatch = useDispatch()


	const candidateAnswer   = useSelector(state => state.appData.candidateAnswer)

	
	const [showEncuesta, setShowEncuesta] = useState(false);
	const [showCandidatos, setShowCandidatos] = useState(true);
	const [candidatoSelected, setCandidatoSelected] = useState({});

	
	

	


	const handleHideEncuesta = () => {
	    setShowEncuesta(false);
	    setShowCandidatos(true);
	    
	}

	const handleShowEncuesta = () => {
	    setShowEncuesta(true);
	    setShowCandidatos(false);
	}



	const saveAnswers = () => {
		
		

    const answersClone = Object.assign({}, candidateAnswer); 

    answersClone.answers = JSON.stringify(answersClone.answers)

    console.log(answersClone)

    dispatch(saveCandidateCuestionarioAction(  answersClone  )).then((value) => {
      /*Swal.fire({
        title: '<strong>Cuestionario guardado</strong>',
        icon: 'success', 
        html:'' ,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> OK!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>'
      }) */

      dispatch(setCandidateAnswerCandidateIDAction("-1"))  
	    dispatch(setCandidateAnswerCandidateEstadoAction( "-1") )
	    dispatch(setCandidateAnswerAction([])) 

	    window.location.reload(false);

    }).catch((error) =>{
      
      console.log(error)
    })
 	
}


	


	  

	  //<pre>{JSON.stringify(candidateAnswer, null, 2)}</pre>	
	//
  	return (
  		<Row className="justify-content-md-center">
  			
  			<Col className="col-12">
  				<Candidatos  />

  				{	candidateAnswer.answers.length > 0 ? (
  						<Cuestionario   /> 	
  					) : (
  						null
  					)

  				}	
  				
  				

  			</Col>
  			<Col>
  				
  				{	candidateAnswer.answers.length > 0 ? (
  						<Button variant="primary" onClick={saveAnswers}> Guardar respuestas </Button>		
  					) : (
  						null
  					)

  				}	
  					
  			</Col>
  		</Row>
	    
)};




export default NewCuestionario
