import React from 'react'
import {Button,Card, Row,Col } from 'react-bootstrap'
// import  { useEffect, useState } from "react";

import './invita.css';





const CandidatoCard = (props) => {

  const botonTwitCandidato = '¡Invítalo a contestar!';
	const botonTwitCandidata = '¡Invítala a contestar!';

  const mensajeTwitCandidato = 'Estimado candidato me gustaría conocer tus respuestas al cuestionario de @_Votoinformado';
  const mensajeTwitCandidata = 'Estimada candidata me gustaría conocer tus respuestas al cuestionario de @_Votoinformado';
  
  const getTwitter = () => {
      const twitterFound = JSON.parse(props.candidato.social).find(social => social.type === "twitter" );
      return twitterFound.url
  }

  const showCuestionarioModal=()=>{
  	props.showCuestionarioModal(props.candidato)
  }

  const handleKeyTabs = () => {
    props.setKey('elecciones');
    if(props.candidato.estado === 1){
      props.handleSelect('coahuilaTab');
    }else{
      if(props.candidato.estado === 0){
        props.handleSelect('edoMexTab');
      }
    }
  }


  return (
  	<Card>

	  	<div class="image-container">
        <Row>
          <Col xs={12}><h7 className='text-center'><b>{props.candidato.name} </b></h7></Col>
          <Col xs={12}><h7 className='text-center'><b>{props.candidato.lastname} </b></h7></Col>

        </Row>
        
	  	  
        <a href={props.urlCard} onClick={handleKeyTabs}> 
           <img src={props.candidato.image_url}  alt="Avatar" className="img-fluid image-overlay" />
        </a> 
        


	  	  <div class="middle">
          
        </div>
	  	</div>



	  	<Card.Body>
		  	<Card.Text className='text-center'>
          {parseInt(props.candidato.with_answers) === 1 ? 
           <Row>
              <Col xs={12}>
                  <img className='img-button-ya-contesto' src='/static/button_ya_contesto.jpg' alt='Ver respuestas' />
              </Col>
              <Col xs={12}>
                <Button  className=' button-ya-contesto mt-2' variant="success"  active onClick={showCuestionarioModal}>
              
                Ver respuestas 

              </Button>
              </Col>
            </Row>
            :
            <div className="selfCenter spaceBetween text-center">
              <Row>
              <Col xs={12}>
                <img className='img-button-ya-contesto' src='/static/button_ no_ha_contestado.jpg' alt='Ver respuestas' />
              
              </Col>
              <Col xs={12}>
                <a className='' href={'https://twitter.com/intent/tweet?screen_name='+getTwitter()+'&text='+(props.candidato.estado === 1 ? mensajeTwitCandidato : mensajeTwitCandidata)} >
                  {<Button className='button-twitter mt-2'>
                                   
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                    {(props.candidato.estado === 1 ? ' '+botonTwitCandidato : ' '+botonTwitCandidata)} 
                                  </Button>
                  }
                  
                </a>
                   </Col>
              </Row>
              <script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            </div>
          }   
        </Card.Text>
  		</Card.Body>
  	</Card>
  );


  /*

<Card>
                    <div class="image-container">
                        <img src={candidato.image_url}  alt="Avatar" class="img-fluid image-overlay" />
                        <div class="middle">
                             <Button variant="primary" size="lg" active onClick={showCuestionarioModal}>
                                Ver respuestas
                              </Button>
                        </div>
                      </div>
                    <Card.Body>
                      <Card.Text>{candidato.name}</Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="selfCenter spaceBetween text-center">
                      <TwitterMentionButton
                        onLoad={function noRefCheck(){}}
                        options={{
                          buttonHashtag: undefined,
                          screenName: undefined,
                          size: 'large'
                        }}
                        placeholder="Loading"
                        screenName={getTwitterUserName(candidato.twitter)}
                      />
                    </div>


  */



};

export default CandidatoCard