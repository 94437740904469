import React from 'react'
import { Modal, Button, Container } from 'react-bootstrap'

const TarjetaModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={false}>
        <Modal.Body>
            <Container>
                <h4><div style={{color:'#F7931E'}}>{(props.foro ? props.foro.title : '')}</div></h4>
                <br />
                <p className='text-center'> <b>{(props.foro ? props.foro.description : '')}</b></p>
                <br />
                {  (props.foro && props.foro.title === 'FORO 1') ?
                    <ul>
                        <li>Dia: {(props.foro ? props.foro.dia : '')}</li>
                        <li>Hora: {(props.foro ? props.foro.hora : '')}</li>
                        <li>Sede: {(props.foro ? props.foro.sede : '')}}</li>
                    </ul>
                :
                    null
                }
                <div>
                    <a href={props.link} target="_blank" rel="noopener noreferrer">
                        {props.link}
                    </a>
                </div>
            </Container>

        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default TarjetaModal
