import React from 'react'
import  { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux'

import {saveUserCuestionarioAction,updateAnswerVisibilityAction,setCurrentAswerAction,setUserAswerAction,setUserInfoAction,setCurrentQuestionToShowAction,fetchCuestionarioAction,setUserAswersCodeAction,setUserAswersAction,setUserAswerMultipleAction,updateAnswerVisibilityMultipleAction} from '../../../../actions/cuestionariosActions'



import { Badge,Alert,Form,Row, Col,Button,Card,ListGroup ,Accordion} from 'react-bootstrap'

import Swal from 'sweetalert2'






import cuestionarioJSONedoMex from '../../../../cuestionarios_json/cuestionario_de_usuario_edo_mex.json';
import cuestionarioJSONcoahuila from '../../../../cuestionarios_json/cuestionario_de_usuario_coahuila.json';

import cuestionarioJSON from '../../../../cuestionarios_json/cuestionario_de_usuario.json';



import CandidateCard from './candidate_card';


import styles from './new.css';





function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


function Block(props) {

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)

  if(currentQuestionToShow)
    switch (currentQuestionToShow.name) {
      case "welcome-screen":
        return(
          <Welcome key={uuidv4()} goToNextQuestion={props.goToNextQuestion}/>
        )
      case "end-screen":
          return(
          <End key={uuidv4()}   goToNextQuestion={props.goToNextQuestion} goToPrevQuestion={props.goToPrevQuestion} saveAnswers={props.saveAnswers} showAnswers={props.showAnswers}/>
        )
        
      case "statement":
        return(
          <Statement key={uuidv4()} goToNextQuestion={props.goToNextQuestion} goToPrevQuestion={props.goToPrevQuestion}/>
        )

      case "question":
      
        return(
          <Question  goToNextQuestion={props.goToNextQuestion} goToPrevQuestion={props.goToPrevQuestion} userOptionSelected={props.userOptionSelected}/>
        )
        break
      default:
        
    }
    
}


function Welcome(props) {

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
      return (
        <Row>
          <Col className='col-8 offset-2'>
            <Row>
              <Col className='col-12 text-center'>
                <img  class='img-fluid img-cuestionario' src={currentQuestionToShow.attributes.attachment.url} alt=' '  />
              </Col>
              <Col className='col-12 mt-3'>
                <h2>{currentQuestionToShow.attributes.label}</h2>
              </Col>
              <Col className='col-12 mt-3'>
                <p>{currentQuestionToShow.attributes.description} </p>
              </Col>
              <Col className='col-12 action mt-3 text-center'>
                <Button variant="primary" onClick={props.goToNextQuestion} >{currentQuestionToShow.attributes.buttonText}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    
}

function End(props) {

const dispatch = useDispatch()
  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
  const answersCode   = useSelector(state => state.appData.answersCode)

  const [cuestionario, setCuestionario] = useState(cuestionarioJSON);




  const [showSaveButton, setShowSaveButton] = useState(true);
 

  useEffect(() => {

   

    if(answersCode == undefined){
      setShowSaveButton(true)   
    }else{
      setShowSaveButton(false)
    }
    
    
  }, [answersCode])


  

  const hanldeSeeAnswersClick = (event) => {  
    
  }


      return (
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }} >
            <Row>
              <Col className='col-12 text-center'>
                <img  class='img-fluid img-cuestionario' src={currentQuestionToShow.attributes.attachment.url} alt=' '  />
              </Col>
              <Col xs={12} className='mt-3 text-center'>
                <h2>{currentQuestionToShow.attributes.label}</h2>
              </Col>
              <Col className='col-12 mt-3 '>
                <p>{currentQuestionToShow.attributes.descriptixon} </p>
              </Col>
              <Col className='col-12 action mt-3 text-center'>
                { answersCode == undefined ? (
                   <Button  variant="primary" onClick={props.saveAnswers}  >Guardar respuestas</Button>
                  ) : (
                    <Button  variant="primary" onClick={props.showAnswers}  >Ver respuestas</Button>
                  )

                }


                
                
              </Col>
            </Row>
          </Col>
        </Row>
      );
    
}


function Statement(props) {

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
    return (
          <Row>
            <Col className='col-8 offset-2 text-center'>
              <Row>
                <Col className='col-12'>
                  <h3>{currentQuestionToShow.attributes.label}</h3>
                </Col>
                <Col className='col-12 text-center mt-3'>
                  <Button className='me-4' variant="secondary" onClick={props.goToPrevQuestion}>Regresar</Button>
                  <Button variant="primary" onClick={props.goToNextQuestion}>{currentQuestionToShow.attributes.buttonText}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
    );
}


function Question(props) {

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
  //<CandidatesResponses />
    return (
      <Row>

          <Col xs={12}>
            {/*<Alert key='success' variant='primary'>*/}
              <Row>
                <Col xs={12} md={8} lg={9} xl={10} xxl={10}> 
                  <h5>{currentQuestionToShow.attributes.block}</h5>
                  <h4>{currentQuestionToShow.attributes.label}</h4>
                </Col>
                <Col xs={12} md={4} lg={3} xl={2}  xxl={2}>
                  <Button className='me-4' variant="primary" onClick={props.goToPrevQuestion}>Anterior</Button>
                  <Button variant="primary" onClick={props.goToNextQuestion}>Siguiente </Button>
                </Col>
              </Row>                     
            {/*</Alert>*/}
            
              
          </Col>
          <Col xs={12}>
            <Row> 
              <Col xs={12} sm={12} md={6} lg={3}>
                <Input key={uuidv4()} />
              </Col>
              <Col xs={12}  sm={12} md={6} lg={9} >
               <CandidatesResponses />
              </Col>
            </Row>
            
              
           
          </Col>
          {/*<Col xs={12} md={6} lg={12}>
            <Input key={uuidv4()} />

          </Col>
          <Col xs={12} md={6} lg={12} className='text-center '>

           <CandidatesResponses />
          </Col>*/}
          
      </Row>
    );
}

function CandidatesResponses(props){

  const dispatch = useDispatch()
  const candidatos   = useSelector(state => state.appData.candidatos)
  const userInfo   = useSelector(state => state.appData.userInfo)   
  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)



  function ShowUserEstadoSelectedtitle(){
    if(userInfo.estado == 0 )
      return (
        <div class="alert alert-success form-control mt-2" role="alert">
                  Candidatos de México
        </div>)
    else 
      return (
      <div class="alert alert-success form-control mt-2" role="alert">
                  Candidatos de Coahuila
        </div>)
  }

    

    return (
      <Row> 
        {candidatos.map((candidato,index)=>{
          if(candidato.estado==userInfo.estado ){
            return (
              candidato.estado === 0 ?
              <Col xs={6}>
                <CandidateCard key={uuidv4()} candidate={candidato} question={currentQuestionToShow} />
              </Col>
              :
              <Col xxl={12} xl={12} lg={12} md={12} sm={12}>  
                <CandidateCard key={uuidv4()} candidate={candidato} question={currentQuestionToShow} />
              </Col>
              )

          }
        })}
      </Row>


    );


}


function UserAnsersResume(props) {

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
}

  


/*function Input(props) {
const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)

  switch (currentQuestionToShow.attributes.type) {
    
    case "multiple":
      return(
        <Row> 
          <Col xs={12} md={6} lg={4}>
            <InputMultiple />
          </Col>
          <Col xs={12} md={6} lg={8} >
           <CandidatesResponses />
          </Col>
        </Row>
        
      )
    case "dropdown":
      return(
        <Row> 
          <Col xs={12} md={6} lg={3}>
            <InputDropdown  />
          </Col>
          <Col xs={12} md={12} lg={12} >
           <CandidatesResponses />
          </Col>
        </Row>
      )
    case "priority":
      return(
        <Row> 
          <Col xs={12} sm={12} md={6} lg={3}>
            <InputPriority  />
          </Col>
          <Col xs={12}  sm={12} md={6} lg={9} >
           <CandidatesResponses />
          </Col>
        </Row>
      )
    case "multitext":
      return(
        <Row> 
          <Col xs={12} sm={12} md={6} lg={3}>
            <InputMultiText  />
          </Col>
          <Col xs={12}  sm={12} md={6} lg={9} >
           
          </Col>
        </Row>
      )

      
  }
}*/


function Input(props) {
const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)

  switch (currentQuestionToShow.attributes.type) {
    
    case "multiple":
      return(
        <InputMultiple />
        
      )
    case "dropdown":
      return(
        <InputDropdown  />
      )
    case "priority":
      return(
        <InputPriority  />
      )
    case "multitext":
      return(
        <InputMultiText  />
      )

      
  }
}









/*

  {
  "name": "question",
  "id": "preg0005",
  "attributes": {
    "type": "multitext",
    "visible":false,
    "required": true,
    "label": "Coahuila aaaaaaaaaaaaaaaaaa está usted asdasdas?",
    "buttonText": "Continuar",

    "subquestions":[
      {"id": "preg0005-1","label":"1"},
      {"id": "preg0005-2","label":"2"}, 
      {"id": "preg0005-3","label":"3"}    
    ]
  }
},
  
*/
function InputMultiText(props) {
  
  const dispatch = useDispatch()

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
  const userAswers   = useSelector(state => state.appData.userAswers)
  const answersCode   = useSelector(state => state.appData.answersCode)



  const updateAnswer = (value,subquestion) => {  
    dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id,   value : value, subQuestionId:subquestion.id })).then((value) => {
          
          dispatch(updateAnswerVisibilityMultipleAction({answerId:currentQuestionToShow.id,visibility:true,subAnswerId: value.subQuestionId }))  

        }).catch((error) =>{
          
          console.log(error)
        })    
  }


  function ShowTextForSubQuestion(props){

    const [subquestion, setsubquestion] = useState(props.subquestion);
    const [value, setValue]  = useState("")


    useEffect(() => {
      
      if(value != "")
        updateAnswer(value,subquestion)

    
  }, [value])


    const onTextChange = (subQuestion) =>(event) => {  
      setValue(event.target.value)
    }

    const findCurrentAnswer = (subquestion) => {  
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subquestion.id );
      setValue(subAnswerFound.value)

      return value// 
  }


    return(
       
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{subquestion.label}</Form.Label>
          <Form.Control as="textarea" rows={3} onChange={onTextChange(subquestion)}  value={value}  />
        </Form.Group>   
    )

  }

  function ShowAnswer(props){

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    const subAnswerFound = answerFound.subquestions.find(answer => answer.id === props.subquestion.id );

    const choiceFound = props.subquestion.attributes.choices.find(choice => choice.value === subAnswerFound.value );

    var label=""
    var badge="primary"

    if(choiceFound){
      label = choiceFound.label
    }else{
      label = "NO RESPONDIÓ"
      badge="danger"
    }

    return(



        <Alert key='success' variant='success'> 
          <Badge bg={badge} > {label}</Badge>
        </Alert>
      )

  }

  return(

      <Row className='subquestions  form-control mt-3 mb-3 shadow'>
        {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
          <Col xs={12} key={uuidv4()} className="mt-2">
              <Row>
                <Col xl={12}>
                  
                  { answersCode == undefined ? (
                      
                         <ShowTextForSubQuestion subquestion={subquestion}/>
                      
                    ) : (
                       <ShowAnswer subquestion={subquestion} /> 
                    )
                  }
                  

                  
                </Col>
              </Row>
          </Col>
        ))} 

      </Row>
  )

  
    
}


function InputPriority(props) {

  const dispatch = useDispatch()

  const answersCode   = useSelector(state => state.appData.answersCode)
  const userAswers   = useSelector(state => state.appData.userAswers)
  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)


  



  const [choices, setchoices]= useState(currentQuestionToShow.attributes.choices);
  
  

  

 useEffect(() => {
    console.log("IN useEffect InputPriority")
   
      var allCheked = true
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      
      answerFound.subquestions.forEach(subanswer => {

        if(subanswer.value === -1 || subanswer.value === ""){
          allCheked = false && allCheked
        }else{
          allCheked =  true && allCheked
        }
      })

      if(allCheked)
        dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true})) 


    
    },[userAswers]) 



  const restartSelects = (optionSelected) => {  
    
    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    answerFound.subquestions.forEach(choice => {
      dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id, value:-1, subQuestionId:choice.id }))
    });


        
  }


  

  function ShowDropDownForPrioritySelect(props){

    const [subquestion, setsubquestion] = useState(props.subquestion);
    const [choices, setchoices]= useState([]);



  
    
    useEffect(() => {
      setAvailableOptions()
      /*var allCheked = true
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      
      answerFound.subquestions.forEach(subanswer => {

        if(subanswer.value === -1 || subanswer.value === ""){
          allCheked = false && allCheked
        }else{
          allCheked =  true && allCheked
        }
      })

      if(allCheked)
        dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true})) 

  */
    
    }, [])  

    const findCurrentAnswer = (subQuestionId) => {  
     
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
      return subAnswerFound  
    }


    const onInputChangeDropdown = (subQuestion) => (event) => {  
  

    dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id, value:event.target.value, subQuestionId:subQuestion.id })).then((value) => {
        
      dispatch(updateAnswerVisibilityMultipleAction({answerId:currentQuestionToShow.id,visibility:true,subAnswerId: value.subQuestionId }))  

    }).catch((error) =>{
      
      console.log(error)
    })
    }

    const getOptionSelectedLabel = (subQuestionId) => {  
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
      const choice = currentQuestionToShow.attributes.choices.find(choice => choice.value === subAnswerFound.value)
      return choice.label

    }


    const setAvailableOptions = () => {  
      var choices = []
      var answerFound = null
      var subAnswerFound =null

      currentQuestionToShow.attributes.choices.forEach(choice => {

        
        answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
        subAnswerFound =answerFound.subquestions.find(answer => answer.value === choice.value )
        if(subAnswerFound == undefined){
          choices.push({label: choice.label,value:choice.value})  
        }



      })

      setchoices(choices)

    }

    //<pre>{JSON.stringify(choices, null, 2)}</pre>
    //optionSelected: {findCurrentAnswer(subquestion.id).value  }
    return(
      <div>
        
        {  findCurrentAnswer(subquestion.id).value ===-1 ? (
            <Form.Select  defaultValue={findCurrentAnswer(subquestion.id).value} size="lg" id={"forms-"+subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
            <option  key={uuidv4()}  value='-1'>Selecciona una opción</option>
            {choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
              
            
            ))}
          </Form.Select>
          ) : (
            <div className="form-control"> {getOptionSelectedLabel(subquestion.id) } </div>
          )

        }
      </div>
    )
  }


  function ShowAnswer(props){

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      const subAnswerFound = answerFound.subquestions.find(answer => answer.id === props.subquestion.id );
    const choiceFound = currentQuestionToShow.attributes.choices.find(choice => choice.value === subAnswerFound.value)

    

    var label=""
    var badge="primary"

    if(choiceFound){
      label = choiceFound.label
    }else{
      label = "NO RESPONDIÓ"
      badge="danger"
    }

    return(



        <Alert key='success' variant='success'> 
          <Badge bg={badge} > {label}</Badge>
        </Alert>
      )

  }



  //<pre>{JSON.stringify(choices, null, 2)}</pre>
  return(
    
            <Row className='subquestions  form-contro mt-3 mb-3 shadow'>
                
              
              {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
                <Col xl={12}  key={uuidv4()} className="mt-2">
                  <Form.Group className="mb-2" controlId={"formg-"+subquestion.id}>
                    <Form.Label>{subquestion.label}</Form.Label>
                      { answersCode == undefined ? (
                          <ShowDropDownForPrioritySelect subquestion={subquestion}/> 
                        ) : (
                             <ShowAnswer subquestion={subquestion} /> 
                        )
                      }
                     
                  </Form.Group>
                </Col>
              ))}
              <Col xl={12}  key={uuidv4()} className="mt-2">
                { answersCode == undefined ? (
                       <Button variant="primary" type="button" onClick={restartSelects}>Reiniciar</Button>
                    ) : (
                       null
                    )
                }
                

              </Col>
            </Row>
  ) 
  
    
}





function InputMultiple(props) {

  const dispatch = useDispatch()

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
  const userAswers   = useSelector(state => state.appData.userAswers)
  const answersCode   = useSelector(state => state.appData.answersCode)


  useEffect(() => {
    
    var allCheked = true
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      
      answerFound.subquestions.forEach(subanswer => {

        if(subanswer.value === -1 || subanswer.value === ""){
          allCheked = false && allCheked
        }else{
          allCheked =  true && allCheked
        }
      })


      
      if(allCheked || answersCode!= undefined)
        dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true}))   
    
  }, [userAswers])

  

  function ShowDropDownForSubQuestion(props){

  
  const [subquestion, setsubquestion] = useState(props.subquestion);
  


  /*useEffect(() => {
    console.log("IN useEffect")
    var allCheked = true
      const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
      
      answerFound.subquestions.forEach(subanswer => {

        if(subanswer.value === -1 || subanswer.value === ""){
          allCheked = false && allCheked
        }else{
          allCheked =  true && allCheked
        }
      })


      console.log(allCheked)
      if(allCheked || answersCode!= undefined)
        dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true}))   
    
  }, [userAswers]) */

  const findCurrentAnswer = (subQuestionId) => {  
    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    const subAnswerFound = answerFound.subquestions.find(answer => answer.id === subQuestionId );
    return subAnswerFound.value
  }

  const onInputChangeDropdown = (subQuestion) =>(event) => {  
    dispatch(setUserAswerMultipleAction({id:currentQuestionToShow.id,   value : event.target.value, subQuestionId:subQuestion.id })).then((value) => {
      
      dispatch(updateAnswerVisibilityMultipleAction({answerId:currentQuestionToShow.id,visibility:true,subAnswerId: value.subQuestionId }))  

    }).catch((error) =>{
      
      console.log(error)
    })
  }


    return(
        <Form.Select  defaultValue={findCurrentAnswer(subquestion.id)} size="lg" id={subquestion.id} onChange={onInputChangeDropdown(subquestion) }>
          <option  key={uuidv4()} disabled value='-1'>Selecciona una opción</option>
          {subquestion.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
    )
  }


  function ShowAnswer(props){

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    const subAnswerFound = answerFound.subquestions.find(answer => answer.id === props.subquestion.id );

    const choiceFound = props.subquestion.attributes.choices.find(choice => choice.value === subAnswerFound.value );

    var label=""
    var badge="primary"

    if(choiceFound){
      label = choiceFound.label
    }else{
      label = "NO RESPONDIÓ"
      badge="danger"
    }

    return(



        <Alert key='success' variant='success'> 
          <Badge bg={badge} > {label}</Badge>
        </Alert>
      )

  }

  return(

      <Row className='subquestions  form-control mt-3 mb-3 shadow'>
        {currentQuestionToShow.attributes.subquestions.map((subquestion) => (
          <Col xs={12} key={uuidv4()} className="mt-2">
              <Row className='mb-4'>
                <Col xl={12}>
                   {subquestion.attributes.label}
                </Col> 
                <Col xl={12}>
                  { answersCode == undefined ? (
                       <ShowDropDownForSubQuestion subquestion={subquestion}/> 
                    ) : (
                       <ShowAnswer subquestion={subquestion} /> 
                    )
                  }


                  
                </Col>
              </Row>
          </Col>
        ))} 

      </Row>
  ) 
}


function InputDropdown(props) {

  const dispatch = useDispatch()
  const answersCode   = useSelector(state => state.appData.answersCode)
  const userAswers   = useSelector(state => state.appData.userAswers)
  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)


  //const [cuestionario, setCuestionario] = useState(cuestionarioJSON);


  

  useEffect(() => {

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    if (answerFound.value != -1 || answersCode!= undefined){
      dispatch(updateAnswerVisibilityAction({answerId:currentQuestionToShow.id,visibility:true}))  
    }
    
  }, [userAswers]) 



  const onInputChangeDropdown = (event) => {  
    dispatch(setUserAswerAction({id:currentQuestionToShow.id,   value : event.target.value}))


  }


  const findCurrentAnswer = () => {  

    const answerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    return answerFound.value
  }


  function ShowDropDown(){
    return(
        <Form.Select  defaultValue={findCurrentAnswer()} size="lg" id={currentQuestionToShow.id} onChange={onInputChangeDropdown}>
          <option  disabled value='-1'>Selecciona una opción</option>
          {currentQuestionToShow.attributes.choices.map((choice) => (
              <option key={uuidv4()} value={choice.value}>{choice.label}</option>
          ))}
        </Form.Select>
      )
  }

  function ShowAnswer(){

    const saveAnswerFound = userAswers.find(answer => answer.id === currentQuestionToShow.id );
    const choiceFound = currentQuestionToShow.attributes.choices.find(choice => choice.value === saveAnswerFound.value );
    var label=""
    var badge="primary"
    if(choiceFound){
      label = choiceFound.label
    }else{
      label = "NO RESPONDIÓ"
      badge="danger"
    }
    

    //
    return(
        <Alert key='success' variant='success'>
          <Badge bg={badge} > {label}</Badge>
        </Alert>
      )
  }




      return(
          <Form.Group className='mt-2 mb-4' controlId="">
            { answersCode == undefined ? (
                 <ShowDropDown />
              ) : (
                 <ShowAnswer /> 
              )
            }
          </Form.Group>
      )
  
    
}


const Cuestionario = (props) => {

  const dispatch = useDispatch()

  const currentQuestionToShow  = useSelector(state => state.appData.currentQuestionToShow)
  const userInfo   = useSelector(state => state.appData.userInfo) 
  const userAswers   = useSelector(state => state.appData.userAswers)
  //const [cuestionario, setCuestionario] = useState([cuestionarioJSON]);
  const [cuestionario, setCuestionario] = useState( [] );



  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [nextQuestion, setnextQuestion] = useState(1);
  const [totalQuestions, settotalQuestions] = useState(0);
  const [finished, setfinished] = useState(false);

  useEffect(() => {
    

    //setCuestionario(cuestionarioJSON)
    //settotalQuestions(cuestionario.length)

    /*if(cuestionario.length == 0){
      if(userInfo.estado==0){

          const cuestionarioFinal = cuestionarioJSONedoMex.filter(question => question.attributes.required == true) 


          setCuestionario(  )
          settotalQuestions(cuestionarioJSONedoMex.length)
      }else{

          const cuestionarioFinal = cuestionarioJSONedoMex.filter(question => question.attributes.required == true) 


          setCuestionario(cuestionarioJSONcoahuila.filter(question => question.attributes.required == true))
          settotalQuestions(cuestionarioJSONcoahuila.length)
      }  
    } */




    if(cuestionario.length == 0){
      var cuestionarioSelected = []
      
      if(userInfo.estado==0){
          cuestionarioSelected= cuestionarioJSONedoMex.filter(question => question.attributes.required == true) 
      }else{
          cuestionarioSelected = cuestionarioJSONcoahuila.filter(question => question.attributes.required == true) 
      }  

      setCuestionario(cuestionarioSelected  )
      settotalQuestions(cuestionarioSelected.length)  
    }

    

    


    if( currentQuestionToShow== undefined){
      dispatch(setCurrentQuestionToShowAction(cuestionario[0]))
    }else{
       if(currentQuestionToShow.id=== "welcome-screen"){
          setcurrentQuestion(0)
          setnextQuestion(1)
       }

    }




    
  }, [currentQuestionToShow,cuestionario]) //currentAnswer





  const onInputChange = (event) => {  
    
  }





  const goToNextQuestion = () => {
    
    if(nextQuestion < totalQuestions){
      dispatch(setCurrentQuestionToShowAction(cuestionario[nextQuestion]))
      
    }
    setcurrentQuestion(nextQuestion)
    setnextQuestion(nextQuestion+1)

} 





const goToPrevQuestion = () => {
 
    dispatch(setCurrentQuestionToShowAction(cuestionario[currentQuestion-1]))



    setnextQuestion(currentQuestion)
    setcurrentQuestion(currentQuestion-1)
}



const showAnswers = () => {
    //alert("ver respuestas")
    dispatch(setCurrentQuestionToShowAction(cuestionario[0]))
} 


  return (
    //<pre>{JSON.stringify(currentQuestionToShow , null, 2)}</pre>
    <Row>
      <Col class='col-12'>
          <Block   goToNextQuestion={goToNextQuestion} goToPrevQuestion={goToPrevQuestion} saveAnswers={props.saveAnswers} userOptionSelected={onInputChange} showAnswers={showAnswers}/>
        
      </Col>
    </Row>
  )

}



function UserFormm(props) {

    const dispatch = useDispatch()
    const userInfo   = useSelector(state => state.appData.userInfo)   
    const [user, setUser] = useState({email:"",age:0,genero:-1,estado:-1});


    useEffect(() => {

      dispatch(setUserInfoAction(user))
    
  }, [user])

    const onInputGeneroChange = (event) => {
        var userC = Object.assign({}, user);
        userC.genero = event.target.value
        setUser(userC)
        

        
    }

    const onInputEstadoChange = (event) => {
          var userC = Object.assign({}, user);
          userC.estado = parseInt(event.target.value)
          setUser(userC)
          dispatch(setUserInfoAction(user))
    }

    const onInputEmailChange = (event) => {
          var userC = Object.assign({}, user);
          userC.email = event.target.value
          setUser(userC)
    }

    const onInputAgeChange = (event) => {
          var userC = Object.assign({}, user);
          userC.age = event.target.value
          setUser(userC)
    }


    const saveUserInfo = (event) => {

      if(user.estado == -1){
        Swal.fire({
          title: 'Error!',
          text: 'Para continuar es necesario seleccionar un estado de la lista',
          icon: 'error',
          confirmButtonText: 'OK'
        })  
      }else{
        dispatch(setUserInfoAction(user))

        var cuestionario =[]
        if(userInfo.estado==0){
          cuestionario = cuestionarioJSONedoMex
        }else{
          cuestionario = cuestionarioJSONcoahuila
        }


        const userAnswers=[]
        cuestionario.forEach(question => {
            if(question.name === "question"){

              var answer = {id:question.id, value:-1, subquestions:[]}

              if(question.attributes.type=="multiple"){
                question.attributes.subquestions.forEach(subquestion => { 
                  answer.subquestions.push({id:subquestion.id, value:-1})
                });
              }
              if(question.attributes.type=="priority"){
                question.attributes.subquestions.forEach(subquestion => { 
                  answer.subquestions.push({id:subquestion.id, value:-1})
                });
              }

              if(question.attributes.type=="multitext"){
                question.attributes.subquestions.forEach(subquestion => { 
                  answer.subquestions.push({id:subquestion.id, value:-1})
                });
              }

              userAnswers.push( answer )
              
            }
        });
        dispatch(setUserAswersAction(userAnswers))
        dispatch(setCurrentQuestionToShowAction(cuestionario[0]))



        props.handleShowEncuesta()
       }
    }

    

      if (props.show) {   
        
       return (
          <div className='vertical-center '>
            <Row>
                <Col className='col-6 offset-3'>
                  <Form >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>¿De qué estado eres?</Form.Label>
                      <Form.Select defaultValue='-1' size="lg" onChange={onInputEstadoChange}>
                          <option   disabled value='-1'>Selecciona una opción</option>
                          <option   value='0'>Estado de México</option>
                          <option   value='1'>Coahuila</option>
                      </Form.Select>
                    </Form.Group>
                    <Button variant="success" type="button" onClick={saveUserInfo}>
                      Responder cuestionario
                    </Button>
                  </Form>
                </Col>
              </Row>     
          </div>
        );

      }
      
    }



    function Encuesta2(props) {
      if (props.show) {   
        return (
          <Row className=''>
            <Col className='col-12'>
              <div>
                <Cuestionario saveAnswers={props.saveAnswers}/>
              </div>
            </Col>
        </Row>
          
        );
      }
    }



function Formm(props) {

      const [userCode, setuserCode ]= useState("");

      const answersCode   = useSelector(state => state.appData.answersCode)


      const onInputEmailChange = (event) => {
        setuserCode(event.target.value)        
      }


    const handlShowAnsers = (event) => {
      
        
      if(answersCode == undefined){

        if(userCode==""){
          Swal.fire({
            title: 'Error!',
            text: 'Indica el código de tus respuestas',
            icon: 'warning',
            confirmButtonText: 'OK'
          })  
        }else{
            props.fetchAnswers(userCode)
        }
          
      }else{
        props.fetchAnswers(answersCode)

      }

    }





    




      if (props.show) {   
        
        return (

          <div className='vertical-center '>
                   

              
                  <Row>
                    <Col></Col>
                    <Col className='text-center'>
                      <Form >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          
                          { answersCode == undefined ?  (
                            <div> 
                              <Form.Label>¿Ya contestaste la encuesta?</Form.Label>
                              <Form.Control type="text" placeholder="Ingresa tu código" onChange={onInputEmailChange} />
                            </div>
                              
                            ) : (
                              <div> 
                                <Form.Label>Ya cuentas con un código de respuestas</Form.Label>
                                <Alert key='success' variant='primary'>
                                  <b>{answersCode}</b>
                                </Alert>
                              </div>
                              

                            )

                          }


                          
                          
                        </Form.Group>
                        <Button variant="success" type="button" onClick={handlShowAnsers}>
                          Ver respuestas
                        </Button>
                      </Form>
                    </Col>
                    <Col></Col>
                  </Row>


                  <Row className='mt-4 pt-4 text-center'>
                    <Col></Col>
                    <Col className=''>
                      <div> <Form.Label>¿Aún no respondes la encuesta?</Form.Label> </div>
                      <div>
                      <Button variant="primary" onClick={props.handleShowUserForm}> Responder encuesta </Button></div>
                    </Col>
                    <Col></Col>
                  </Row> 
              
          </div>
        );

      }
      
    }



const NewCuestionario = () => {


const dispatch = useDispatch()



  const userAswers   = useSelector(state => state.appData.userAswers)
  const userInfo   = useSelector(state => state.appData.userInfo)
  const answersCode   = useSelector(state => state.appData.answersCode)



  const [showEncuesta, setShowEncuesta] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showUserForm, setshowUserForm] = useState(false);

  const [estadoSelected, setestadoSelected] = useState(false);



  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {

   

    if(answersCode != undefined){

    }
    
    
  }, [answersCode])


  



  const handleShowEncuesta = () => {
      setShowEncuesta(true);
      setShowForm(false);
      setshowUserForm(false);
  }


  const handleShowUserForm = () => {
      dispatch(setUserAswersCodeAction(  undefined ))  
      setShowEncuesta(false);
      setShowForm(false);
      setshowUserForm(true);  
  }


  const handleHideEncuesta = () => {
      setShowEncuesta(false);
      setShowForm(true);
  }

  const fetchAnswers = (userCode) => {
   

    dispatch(fetchCuestionarioAction(userCode)).then((value) => {
      

      if(value.status== 404){
        Swal.fire({
          title: '<strong>No encontrado</strong>', 
          icon: 'warning', 
          html:
            'No se encontraron las respuestas con el código <br> '+
            '<div class="mt-3 mb-3"><b>'+userCode+'</b></div> ' +
            'Inténtalo nuevamente con otro código' ,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> OK!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>'
        }) 
      }

      if(value.status== 200){

        handleShowEncuesta()

      }


    }).catch((error) =>{
      
      console.log(error)
    })
    
   


  }

  const handleChangeEmail = (event) => {
    setUserEmail(event.target.value)
  }



  const saveAnswers = () => {


    const answersClone = Object.assign([], userAswers); 
    dispatch(saveUserCuestionarioAction(  {"answers":  JSON.stringify(answersClone) , "user": userInfo}  )).then((value) => {
      Swal.fire({
        title: '<strong>Cuestionario guardado</strong>',
        icon: 'success', 
        html:
          'Puedes usar el siguiente código <br> '+
          '<div class="mt-3 mb-3"><b>'+value+'</b></div> ' +
          'para comparar tus respuestas nuevamente' ,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> OK!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>'
      })  

    }).catch((error) =>{
      
      console.log(error)
    })
  
}

  //<pre>{JSON.stringify(answersCode, null, 2)}</pre>
//<pre>{JSON.stringify(userAswers, null, 2)}</pre>
    
    return (
      <Row className="justify-content-md-center full-height-cuestionario cuestionario-bg" >
       <Col className="col-12 container-cuestionario">
          
            <Formm show={showForm} handleShowUserForm={handleShowUserForm}    fetchAnswers={fetchAnswers}/>
            <UserFormm show={showUserForm}  handleShowEncuesta={handleShowEncuesta}/>
            <Encuesta2  show={showEncuesta} saveAnswers={saveAnswers}/> 
          
        </Col>
      </Row>
      
)};

export default NewCuestionario
