import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ButtonGroup, Button, Modal,  }  from 'react-bootstrap';

import './footer.css';


const Footer = () => {
  
  const [showOficio, setShowOficio] = useState(false);

  return (
    <Container fluid className="footer-bg footer-container">
      <Row className="pt-4">
        <Col sm={12} md={12} className='col-center pb-3'>
          <a href='#' onClick={()=> setShowOficio(true)}>Opinión sobre la operación del portal durante la Veda Electoral</a>
        </Col>
        <Col sm={12} md={6}>
          <div>
            Circuito Mario de la Cueva S/N, Ciudad Universitaria, Alcaldia Coyoacán, Ciudad de México, C.P. 04510
          </div>
          <div class='pb-4'>
            contacto.voto@politicas.unam.mx Tel. 55 3005 0333 {/* 55 3237 5322 */}
          </div>
        </Col>
        <Col sm={0} md={2}></Col>
        <Col sm={12} md={4} className='col-center pb-3'>
            <ButtonGroup >
              <a href="https://www.facebook.com/unam.votoinformado" target='_blank' className='px-2'>
                <img src='/static/iconos_redes/Face.png' className='footer-img'/>
              </a>
              <a href='https://twitter.com/_VotoInformado' target='_blank' className='px-2'>
                <img src='/static/iconos_redes/Twitter.png' className='footer-img'/>
              </a>
              <a href='https://www.instagram.com/_votoinformado/' target='_blank' className='px-2'>
                <img src='/static/iconos_redes/Insta.png' className='footer-img' />
              </a>
              <a href='https://www.youtube.com/@VotoInformadoUNAM/videos' target='_blank' className='px-2'>
                <img src='/static/iconos_redes/Youtube.png' className='footer-img'/>
              </a>
          </ButtonGroup>
        </Col>  
      </Row>
      <Row className="text-center">
        <Col >
          <div>Hecho en México, Universidad Nacional Autónoma de México (UNAM), todos los derechos reservados 2023.</div>
            <div>Esta página puede ser reproducida con fines no lucrativos, siempre y cuando no se mutile, se cite la fuente completa y su dirección electrónica.</div>
            <div>De otra forma, requiere permiso previo por escrito de la institución.</div>
         </Col>
      </Row>
      <Modal show={showOficio} onHide={()=> setShowOficio(false)} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
        <Modal.Body>
        <div className='img-center'>
            <iframe src={'static/oficio/INE-DJ-6175-2023.pdf'} 
                    width="1000" 
                    height="690">
            </iframe>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={()=> setShowOficio(false)}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>
    </Container>
  )
}

export default Footer




