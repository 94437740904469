import React, { useState } from 'react'
import { Nav,Tab,Container,Row,Col,Modal,Button } from 'react-bootstrap'

import { useSelector } from 'react-redux'


import CandidateCard from './candidate_card'

import styles from './index.css';


import { uuidv4 } from "../../helper";



const Inicio = (props) => {
  const candidatos = useSelector(state => state.appData.candidatos)
  // <pre>{JSON.stringify(candidato , null, 2)}</pre>
  const [platform, setPlatform] = useState('');
  const [showPlatform, setShowPlatform] = useState(false);
  const openPlatform = (candidatePlatform) => {
    setPlatform(candidatePlatform);
    setShowPlatform(true);
  }
  const closePlatform = () =>{
    setShowPlatform('');
    setShowPlatform(false);
  }

  return (
    <Container className='mt-4 pt-4'>


      <Tab.Container id="left-tabs-example " //defaultActiveKey="coahuilaTab" 
        activeKey={props.keyEstado}
        onSelect={props.handleSelect}
      >
              <Nav fill variant="pills" className="flex-colum " >
                <Nav.Item >
                  <Nav.Link eventKey="coahuilaTab">Coahuila</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="edoMexTab">Estado de México</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className=''>
                <Tab.Pane eventKey="coahuilaTab" className=''>

                  {candidatos.map((candidato,index)=>{
                    if(candidato.estado==1){
                      return (
                        <Col key={uuidv4()} xxl={12}  className='mt-4'>
                          <div id={'coahuila-'+index.toString()}>
                         
                          <CandidateCard keyy={uuidv4()} candidate={candidato} setKey={props.setKey} openPlatf={openPlatform}/> 
                          </div>
                        </Col>
                      )
                    }
                  })}
                  <br></br>
                  <br></br>
                  <Container className='row-center shadow py-5'>
                    <div id='info-coahuila' className='img-infografia'>
                      <img src='/static/inicio/Infografias_estados/Coahuila_10pts.jpg' className='img-info-estado'>
                      </img>
                    </div>
                  </Container>
                  <br></br>
                  <br></br>
                </Tab.Pane>
                <Tab.Pane eventKey="edoMexTab">
                  {candidatos.map((candidato,index)=>{
                    if(candidato.estado==0){
                      return (
                        <Col key={uuidv4()} xxl={12}  className='mt-4'>
                          <div id={'edomex-'+index.toString()}>
                            <CandidateCard keyy={uuidv4()} candidate={candidato} setKey={props.setKey} openPlatf={openPlatform}/>
                          </div>
                        </Col>
                      )
                    }
                  })}
                  <br></br>
                  <br></br>
                  <Container  xs={12} className='row-center shadow py-5'>
                    <div id={'info-edomex'} className='img-infografia'>
                      <img src='/static/inicio/Infografias_estados/EdoMex_10pts.jpg' className='img-info-estado'>
                      </img>
                    </div>
                  </Container>
                  <br></br>
                  <br></br>
                </Tab.Pane>
              </Tab.Content>
      </Tab.Container>

      <Modal show={showPlatform} onHide={()=> closePlatform()} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={true}>
        <Modal.Body>
        <div className='img-center'>
            <iframe src={platform} 
                    width="1080" 
                    height="720">
            </iframe>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={()=> closePlatform()}>
            Cerrar
        </Button>
        </Modal.Footer>
      </Modal>

    </Container>


  )
}

export default Inicio
